import axios from 'axios';
import { BACKEND_URL } from './config';

export const isAuthenticated = async (auth) => {
    let jwt = auth.access_token;
    let reAuth = auth.reAuth;

    if (!jwt) {
        window.location.href = '/login';
        return false;
    }
    else if ((new Date(reAuth) - new Date()) < 900000) {
        let token = await axios
            .get(BACKEND_URL + "user/reauthenticate",
                {
                    headers: {
                        Authorization: ["Bearer", jwt].join(" "),
                    },
                })
            .then(function (response) {
                return response.data.access_token;
            })
            .catch(function (erro) {
                console.log(erro);
                checkAuthenticity(erro.response.status);
                return false;
            })
        return token;
    }
    return true;
};

export const checkAuthenticity = (status) => {
    if (status === 403) {
        document.cookie.split(";").forEach((c) => {
            document.cookie = c
                .replace(/^ +/, "")
                .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
        window.location.href = '/login';
    }
}
