import prop from 'prop-types';
import { Grid2, Typography, Button, Box, Card, CardContent, CardActions } from '@mui/material';
import { textCardStyle } from '../../styles/styles';

export const SectionLearning = ({ id, title, list }) => (
  <Box id={id}>
    <Box component='section'>
      <Typography align='left' variant='h2' sx={textCardStyle}>{title}</Typography>
    </Box>
    <Grid2 container spacing={2} marginY={3} sx={{ textAlign: 'start' }}>
      {list.map((link, index) =>
        <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }} key={index}>
          <Card sx={{ minWidth: 275, height: '100%', display: 'grid' }}>
            <CardContent>
              <Typography gutterBottom variant="h4" component="div">
                {link.title}
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'end', alignItems: 'end' }}>
              <Button variant="contained" rel="noreferrer" href={link.url.includes("https://") ? link.url : `https://drive.google.com/file/d/${link.url}`} target="_blank" >
                Acessar
              </Button>
            </CardActions>
          </Card>
        </Grid2>
      )}
    </Grid2>
  </Box>
);

SectionLearning.propTypes = {
  title: prop.string.isRequired,
  list: prop.array.isRequired,
}
