import prop from 'prop-types';
import { filterCharacters } from '../../util/filter';
import { FormControl, FormLabel, InputAdornment, TextField } from '@mui/material';

export const MoedaInput = ({ id, type, text, value, required, autofocus, onValueChange, maxLength, disabled }) => (
    <FormControl>
        <FormLabel htmlFor={id} sx={{ textAlign: 'start' }}>{text}</FormLabel>
        <TextField
            // error={error}
            // helperText={errorMessage}
            id={id}
            type={type}
            name={id}
            value={value}
            onChange={(e) => {
                let value = e.target.value;
                if (value) {
                    value = filterCharacters(value);
                    value = value.replace(/(\d{2})$/g, ",$1");
                    if (value.length > 6)
                        value = value.replace(/(\d{3})\,(\d{2}$)/g, ".$1,$2");
                    onValueChange(value);
                    return
                }
                onValueChange('');
                return
            }
            }
            // placeholder={placeholder}
            autoFocus={autofocus}
            required={required}
            fullWidth
            variant="outlined"
            // color={error ? 'error' : 'primary'}
            slotProps={{
                htmlInput: { maxLength: { maxLength } },
                input: { startAdornment: <InputAdornment position="start">R$</InputAdornment> }
            }}
            sx={{ ariaLabel: { text } }}
            disabled={disabled}
        />
    </FormControl>
);

MoedaInput.defaultProps = {
    autofocus: false,
    required: true,
    disabled: false,
    maxLength: null,
    type: 'text'
}

MoedaInput.propTypes = {
    id: prop.string.isRequired,
    type: prop.string,
    text: prop.string.isRequired,
    value: prop.oneOfType([prop.string, prop.number]).isRequired,
    autofocus: prop.bool,
    required: prop.bool,
    onValueChange: prop.func.isRequired,
    maxLength: prop.number,
    disabled: prop.bool
}
