import prop from 'prop-types';
import { dateToString } from '../../util/filter';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton, Stack, Typography } from '@mui/material';

export const InfoListCert = ({ title, list, removeItem }) => (
    <Stack>
        <strong>{title}: </strong>
        {list.map((item, index) =>
            <Typography key={index} id={item.id}>
                <strong>{item.nome} -</strong> Val. {dateToString(item.validade)}
                {
                    removeItem ?
                        <IconButton type="button" aria-label="Remover item" onClick={(e) => removeItem(e, item.id, item.nome)} sx={{ m: '8px', float: "right", border: 0, background: 'transparent !important' }}>
                            <RemoveIcon />
                        </IconButton>
                        : ''
                }
            </Typography>
        )
        }
    </Stack>
);

InfoListCert.defaultProps = {
    removeItem: null
}

InfoListCert.propTypes = {
    title: prop.string.isRequired,
    list: prop.array.isRequired,
    removeItem: prop.func
}