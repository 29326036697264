import { isAuthenticated, reAuth } from '../../util/auth';

import { Outlet, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useEffect, useState } from 'react';

const PrivateRoute = () => {
  const [cookies, setCookie] = useCookies(['auth']);

  const [result, setResult] = useState(isAuthenticated(cookies));

  useEffect(() => {
    result.then(value => {
      if (value.length > 0) {
        var now = new Date();
        now.setMinutes(now.getMinutes() + 60);

        //cria um cookie para armazenar o token JWT com o tempo de expiração definido acima
        setCookie('access_token', value, { expires: now, path: '/', secure: true, sameSite: 'Lax' });
        setCookie('reAuth', now, { expires: now, path: '/', secure: true, sameSite: 'Lax' });
      }
    })
  }, [result, setCookie])

  return result ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
