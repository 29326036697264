import { useEffect } from 'react';
import { Input } from '../../components/Input';
import { checkEmpty } from '../../util/checks';
import { AddDocumentHelper } from './helper';
import { Typography, Box, Button, Card, CardContent, CardActions } from '@mui/material';
import { SelectField } from '../../components/SelectField';

export const AddDocument = () => {
    const { searchFuncionarios, searchEmpresas, handleEmpresa, handleFuncionario, downloadContrato, sendContrato, empresas, funcionarios, estadosCivis, empresa, funcionario, estadoCivil, profissao, setEstadoCivil, setProfissao } = AddDocumentHelper();

    useEffect(() => {
        searchFuncionarios();
        searchEmpresas();
    }, [searchFuncionarios, searchEmpresas]);

    return (
        <Card>
            <Box component='form' noValidate>
                <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography gutterBottom variant="h2" component="div">
                            Contrato
                        </Typography>
                    </Box>
                    <SelectField id="empresas" text="Empresas" value={empresa} setoption={handleEmpresa} options={empresas} />
                    <SelectField id="funcionarios" text="Funcionário" value={funcionario} setoption={handleFuncionario} options={funcionarios} />
                    <SelectField id="estadoCivil" text="Estados Civis" value={estadoCivil} setoption={setEstadoCivil} options={estadosCivis} />
                    <Input id="profissao" type="text" text="Profissão" value={profissao} onChange={(e) => setProfissao(e.target.value)} />
                </CardContent>
                <CardActions sx={{ float: 'right', alignItems: 'flex-end', marginY: 2 }}>
                    <Button variant="contained" onClick={(e) => downloadContrato(e)} title='Baixar contrato'>
                        Baixar contrato
                    </Button>
                    <Button variant="contained" onClick={(e) => sendContrato(e)} title='Enviar por email'>
                        Enviar por email
                    </Button>
                </CardActions>
            </Box>
        </Card>
    );
}
