import prop from 'prop-types';
import { Divider, IconButton, Menu, MenuItem, Stack } from '@mui/material';
import { useState } from 'react';
import DehazeIcon from '@mui/icons-material/Dehaze';
import ColorModeSelect from '../../ColorModeSelect';

export const NavBar = ({ header, onClickSignOut, setShow }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <nav className="header navbar nav-bar" ref={header}>
            {/* <IconExpandSidebar sx={{ background: 'transparent !important', border: 0 }} type="button" ref={toggle} onClick={() => openSidebar()}>
                {arrowLeft ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
            </IconExpandSidebar> */}

            <Stack direction="row">
                <ColorModeSelect />
                <Divider orientation="vertical" variant="middle" flexItem sx={{ marginX: 1 }} />
                <IconButton
                    id="options-menu"
                    title='Menu'
                    aria-controls={open ? 'menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={[
                        (theme) => ({
                            backgroundColor: 'white',
                        }),
                    ]}
                >
                    <DehazeIcon />
                </IconButton>
                <Menu
                    id="menu"
                    aria-labelledby="menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem onClick={setShow}>Perfil</MenuItem>
                    <MenuItem onClick={onClickSignOut}>Sair</MenuItem>
                </Menu>
            </Stack>
        </nav>
    )
};

NavBar.propTypes = {
    header: prop.object.isRequired,
    onClickSignOut: prop.func.isRequired,
    setShow: prop.func.isRequired,
}
