import { useEffect } from 'react';
import { Input } from '../../components/Input';
import { MoedaInput } from '../../components/MoedaInput';
import { RadioButton } from '../../components/RadioButton';
import { checkAgencia, checkChaveJ, checkCPF, checkDate, checkEmail, checkEmpty, checkPhone } from '../../util/checks';
import { AgenciaMask, CEPMask, CPFMask, DateMask, EmailMask, formatChars, PhoneMask, RGMask } from '../../util/mask';
import { CardForm } from '../../components/CardForm';
import { CheckButton } from '../../components/CheckButton';
import { FormAddFuncHelper } from './helper';
import { Backdrop, Box, Button, CircularProgress, Divider, Grid2, Stack, Typography } from '@mui/material';
import { SelectField } from '../../components/SelectField';
import { textCardStyle } from '../../styles/styles';

/* Página do formulário para cadastro de funcionários, 
  contendo todos os campos necessários.
*/
export const FormAddFunc = () => {
  const { searchFuncionarios, searchBancos, searchEmpresas, searchSupervisoresAndAdministradores, handleAdicionar, handleShowFuncionarios, selectFuncionario, handleCep, handleConta, handleCheckConta, createInput, createUpdateFunc, showButtons, showSelectFuncionarios, showForm, showChaveJ, showCargo, showCredito, showConsorcio, showContaCorrente, showOurocap, showBbDental, showSeguros, showComissaoSupervisor, showOutros, showAjuste, text, funcionarios, estadosCivis, empresas, supervisores, bancos, comissoes, funcionario, estadoCivil, empresa, supervisor, banco, nome, chavej, email, cpf, rg, expedidor, dataNascimento, dataInicio, profissao, cargo, atuacao, cep, logradouro, bairro, numero, complemento, localidade, uf, tipoConta, variacao, agencia, checkConta, conta, checkPix, tipoPix, pix, salario, ajuda, rendimento, adiantamento, internet, aluguel, telefone, credito, consorcio, contaCorrente, ourocap, bbDental, seguros, comissaoSupervisor, outros, ajuste, setFuncionario, setNome, setChavej, setEmail, setCpf, setRg, setExpedidor, setDataNascimento, setDataInicio, setSexo, setEstadoCivil, setProfissao, setCamisa, setEmpresa, setCargo, setSupervisor, setAtuacao, setLogradouro, setBairro, setNumero, setComplemento, setLocalidade, setUf, setCheckConta, setTipoConta, setVariacao, setAgencia, setBanco, setCheckPix, setTipoPix, setPix, setCusto, setSalario, setAjuda, setRendimento, setAdiantamento, setInternet, setAluguel, setTelefone, setCredito, setConsorcio, setContaCorrente, setOurocap, setBbDental, setSeguros, setComissaoSupervisor, setOutros, setAjuste, sexos, camisas, cargos, tiposConta, tiposPix, custos, comissao, sexo, camisa, showBackDrop, setShowBackDrop, custo } = FormAddFuncHelper();

  useEffect(() => {
    searchFuncionarios();
    searchBancos();
    searchEmpresas();
    searchSupervisoresAndAdministradores();
  }, [searchFuncionarios, searchBancos, searchEmpresas, searchSupervisoresAndAdministradores]);

  return (
    <Box className="height-100">
      {showButtons &&
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" variant="middle" flexItem sx={{ marginX: 2 }} />}
          sx={{ justifyContent: 'center' }}
        >
          <Button variant="contained" onClick={(e) => handleAdicionar()} title='Adicionar'>Adicionar</Button>
          <Button variant="contained" onClick={(e) => handleShowFuncionarios()} title='Modificar'>Modificar</Button>
        </Stack>
      }
      {showSelectFuncionarios &&
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" variant="middle" flexItem sx={{ marginX: 2 }} />}
          sx={{
            justifyContent: 'center', alignItems: 'center'
          }}
        >
          <SelectField id="funcionarios" text="Funcionários" value={funcionario} setoption={setFuncionario} options={funcionarios} />
          <Button variant='contained' onClick={(e) => selectFuncionario()} title='Selecionar'>Selecionar</Button>
        </Stack>
      }
      {
        showForm &&
        <Box id="principal" sx={{ paddingY: 2 }}>
          <Typography variant='h2' sx={textCardStyle}>
            {text}
          </Typography>
          <Divider flexItem sx={{ marginY: 2 }} />
          <Box component='form' onSubmit={createUpdateFunc} noValidate>
            <Grid2 container>
              <CardForm title="Dados Pessoais">
                <Input id="nome" text="Nome Completo" value={nome} onChange={(e) => setNome(e.target.value)} onBlur={checkEmpty} />
                {showChaveJ &&
                  <Input id="chavej" text="ChaveJ" maxLength='8' value={chavej} onChange={(e) => setChavej(e.target.value)} onBlur={checkChaveJ} />
                }
                <Input type='email' id="email" text="Email" value={email} onChange={(e) => setEmail(e.target.value)} onBlur={checkEmail} />
                <Input mask={PhoneMask} id="telefone" text="Telefone" value={telefone} onChange={(e) => setTelefone(e.target.value)} onBlur={checkPhone} />
                <Input mask={CPFMask} id="cpf" text="CPF" value={cpf} onChange={(e) => setCpf(e.target.value)} onBlur={checkCPF} />
                <Input mask={RGMask} id="rg" text="RG" value={rg} onChange={(e) => setRg(e.target.value)} onBlur={checkEmpty} />
                <Input id="expedidor" text="Orgão expedidor" maxLength='20' value={expedidor} onChange={(e) => setExpedidor(e.target.value)} onBlur={checkEmpty} />
                <Input mask={DateMask} id="dataNascimento" text="Data de Nascimento" value={dataNascimento} onChange={(e) => setDataNascimento(e.target.value)} onBlur={checkDate} formatChars={formatChars} />
                <Input mask={DateMask} id="dataInicio" text="Data de Início" value={dataInicio} onChange={(e) => setDataInicio(e.target.value)} onBlur={checkDate} formatChars={formatChars} />
                <RadioButton id='radio-sexo' text='Sexo:' value={sexo} onSelect={setSexo} items={sexos} />
              </CardForm>

              <CardForm title="Endereço">
                <Input mask={CEPMask} id="cep" text="CEP" value={cep} onChange={(e) => handleCep(e.target.value)} onBlur={checkEmpty} />
                <Input id="logradouro" text="Rua" value={logradouro} onChange={(e) => setLogradouro(e.target.value)} onBlur={checkEmpty} />
                <Input id="bairro" text="Bairro" value={bairro} onChange={(e) => setBairro(e.target.value)} onBlur={checkEmpty} />
                <Input id="numero" text="Número" value={numero} onChange={(e) => setNumero(e.target.value)} onBlur={checkEmpty} />
                <Input id="complemento" text="Complemento" value={complemento} onChange={(e) => setComplemento(e.target.value)} required={false} />
                <Input id="localidade" text="Cidade" value={localidade} onChange={(e) => setLocalidade(e.target.value)} />
                <Input id="uf" text="UF" value={uf} onChange={(e) => setUf(e.target.value)} />
              </CardForm>

              <CardForm title='Dados Profissionais'>
                <SelectField id="estadoCivil" text="Estado Civil" value={estadoCivil} setoption={setEstadoCivil} options={estadosCivis} />
                <Input id="profissao" text="Profissão" value={profissao} onChange={(e) => setProfissao(e.target.value)} onBlur={checkEmpty} />
                <RadioButton id='radio-camisa' text='Tamanho de Camisa:' value={camisa} onSelect={setCamisa} items={camisas} />
                {showChaveJ &&
                  <SelectField id="empresa" text="Empresa" value={empresa} setoption={setEmpresa} options={empresas} />
                }
                {showCargo &&
                  <RadioButton id='radio-cargos' text='Cargo:' value={cargo} onSelect={setCargo} items={cargos} />
                }
                {cargo === 'agente' && supervisores.length > 0 &&
                  <SelectField id="supervisores" text="Supervisor" value={supervisor} setoption={setSupervisor} options={supervisores} />
                }
                {cargo === 'supervisor' &&
                  <Input id="supervisorName" text="Supervisor" value={supervisor} onChange={(e) => setSupervisor(e.target.value)} onBlur={checkEmpty} />
                }
                {(cargo === 'agente' || cargo === 'supervisor') &&
                  <Input id="atuacao" text="Agência de atuação" value={atuacao} onChange={(e) => setAtuacao(e.target.value)} onBlur={checkEmpty} />
                }
              </CardForm>

              {showChaveJ &&
                <CardForm title="Dados Financeiros">
                  <RadioButton id='radio-custo' text='Custo:' value={custo} onSelect={setCusto} items={custos} />
                  <MoedaInput id="salario" text="Salário" value={salario} onValueChange={setSalario} required={false} />
                  <MoedaInput id="ajuda" text="Ajuda de custo" value={ajuda} onValueChange={setAjuda} required={false} />
                  <MoedaInput id="rendimento" text="Rendimento fixo" value={rendimento} onValueChange={setRendimento} required={false} />
                  <MoedaInput id="adiantamento" text="Adiantamento" value={adiantamento} onValueChange={setAdiantamento} required={false} />
                  <MoedaInput id="internet" text="Internet" value={internet} onValueChange={setInternet} required={false} />
                  <MoedaInput id="aluguel" text="Aluguel" value={aluguel} onValueChange={setAluguel} required={false} />
                </CardForm>
              }

              <CardForm title="Dados Bancários">
                <CheckButton id="contaCheck" text="Conta bancária" value={checkConta} onSelect={(e) => setCheckConta(!checkConta)} />
                {checkConta &&
                  <RadioButton id='radio-tipo-conta' text='' value={tipoConta} onSelect={setTipoConta} items={tiposConta} />
                }
                {checkConta && tipoConta === 'poupanca' &&
                  <Input mask={Number} id="variacao" text="Variação" value={variacao} onChange={(e) => setVariacao(e.target.value)} onBlur={checkEmpty} />
                }
                {checkConta && (tipoConta === 'poupanca' || tipoConta === 'corrente') &&
                  <>
                    <Input mask={AgenciaMask} id="agencia" text="Agência" value={agencia} onChange={(e) => setAgencia(e.target.value)} onBlur={checkAgencia} />
                    <Input id="conta" text="Conta" value={conta} onChange={(e) => handleConta(e.target.value)} onBlur={handleCheckConta} maxLength='12' />
                    <SelectField id="banco" text="Banco" value={banco} setoption={setBanco} options={bancos} />
                  </>
                }
                <CheckButton id="pixCheck" text="Pix" value={checkPix} onSelect={(e) => setCheckPix(!checkPix)} />
                {checkPix &&
                  <RadioButton id='radio-pix' text='' value={tipoPix} onSelect={setTipoPix} items={tiposPix} />
                }
                {(checkPix && tipoPix === 'email') &&
                  <Input mask={EmailMask} id="pix" text="Pix" value={pix} onChange={(e) => setPix(e.target.value)} onBlur={checkEmail} />
                }
                {(checkPix && tipoPix === 'telefone') &&
                  <Input mask={PhoneMask} id="pix" text="Pix" value={pix} onChange={(e) => setPix(e.target.value)} onBlur={checkPhone} />
                }
                {(checkPix && tipoPix === 'cpf') &&
                  <Input mask={CPFMask} id="pix" text="Pix" value={pix} onChange={(e) => setPix(e.target.value)} onBlur={checkCPF} />
                }
              </CardForm>

              <CardForm title="Comissões">
                <SelectField id="comissao" text="Comissões" value={comissao} setoption={createInput} options={comissoes} />
                {showCredito &&
                  <MoedaInput id="credito" text="Comissão de Crédito" value={credito} onValueChange={setCredito} onBlur={checkEmpty} required={false} />
                }
                {showConsorcio &&
                  <MoedaInput id="consorcio" text="Comissão de Consórcio" value={consorcio} onValueChange={setConsorcio} onBlur={checkEmpty} required={false} />
                }
                {showContaCorrente &&
                  <MoedaInput id="contaCorrente" text="Comissão de Conta Corrente" value={contaCorrente} onValueChange={setContaCorrente} onBlur={checkEmpty} required={false} />
                }
                {showOurocap &&
                  <MoedaInput id="ourocap" text="Comissão de Ourocap" value={ourocap} onValueChange={setOurocap} onBlur={checkEmpty} required={false} />
                }
                {showBbDental &&
                  <MoedaInput id="bbDental" text="Comissão de BB Dental" value={bbDental} onValueChange={setBbDental} onBlur={checkEmpty} required={false} />
                }
                {showSeguros &&
                  <MoedaInput id="seguros" text="Comissão de Seguros" value={seguros} onValueChange={setSeguros} onBlur={checkEmpty} required={false} />
                }
                {showComissaoSupervisor &&
                  <MoedaInput id="comissaoSupervisor" text="Comissão de Supervisor" value={comissaoSupervisor} onValueChange={setComissaoSupervisor} onBlur={checkEmpty} required={false} />
                }
                {showOutros &&
                  <MoedaInput id="outros" text="Comissão Outros" value={outros} onValueChange={setOutros} onBlur={checkEmpty} required={false} />
                }
                {showAjuste &&
                  <MoedaInput id="ajuste" text="Ajuste" value={ajuste} onValueChange={setAjuste} onBlur={checkEmpty} required={false} />
                }
              </CardForm>
              <Grid2 size={12}>
                <Button type="submit" fullWidth variant='contained' id="btnSubmit" title='Enviar' sx={{ float: 'right', marginY: 2 }}>Enviar</Button>
              </Grid2>
            </Grid2>
          </Box>
        </Box>
      }
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={showBackDrop}
        onClick={() => setShowBackDrop(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
