import { Card, CardContent, Divider, Grid2, Stack, Typography } from '@mui/material';
import prop from 'prop-types';

export const CardForm = ({ title, children }) => (
    <Grid2 size={{ xs: 12, md: 6, lg: 4 }}>
        <Card
            sx={{ margin: 2 }}>
            <CardContent>
                <Stack
                    spacing={2}
                    sx={{ padding: 2 }}
                >
                    <Typography variant="h3" align='left'>{title}</Typography>
                    <Divider variant="middle" flexItem />
                    {children}
                </Stack>
            </CardContent>
        </Card>
    </Grid2>
);

CardForm.propTypes = {
    title: prop.string.isRequired,
    children: prop.oneOfType([prop.arrayOf(prop.node), prop.node]).isRequired
}