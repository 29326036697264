import { Card, CardContent, Typography, Grid2 } from '@mui/material';
import prop from 'prop-types';

export const CardInfo = ({ title, children }) => (
  <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h3" component="div">
          {title}
        </Typography>
        {children}
      </CardContent>
    </Card>
  </Grid2>
);

CardInfo.propTypes = {
  title: prop.string.isRequired,
  children: prop.oneOfType([prop.arrayOf(prop.node), prop.node]).isRequired
}