import { useState } from 'react';
import { useParams } from 'react-router-dom';

export const LearningHelper = () => {
  const section = useParams();

  const [portais, setPortais] = useState([
    {
      title: 'Portal Promotiva',
      url: 'https://promotiva.portalcoban.com.br/auth'
    },
    {
      title: 'Portal ABAC',
      url: 'https://abac.org.br/'
    },
    {
      title: 'Portal de Treinamentos',
      url: 'https://wizityparavoce.konviva.com.br/lms/#/home'
    },
    {
      title: 'Portal Promotiva Club',
      url: 'https://campanhas.promotivaclub.com.br/home'
    }
  ]);

  const [normativos, setNormativos] = useState([
    {
      title: 'Treinamento Promotiva - Portabilidade de Crédito',
      url: '1jfxe7vztZiijS6BdhfH9oxBaCai5KXkU'
    },
    {
      title: 'BB Crédito Automático',
      url: '1TXtUso7__gLqt6mx2-ktO7MjFRBQHQgo'
    },
    {
      title: 'Helpcard Crédito Automático',
      url: '1eiGyEyRdYDDxs3_w_CeYAkhZyyiDEE2_'
    },
    {
      title: 'BB Código de Conduta e Integridade',
      url: '1Tjs-HNiMhfmL9g-FlAdHJdhKM8YYohI_'
    },
    {
      title: 'BB Consórcios (FAQ Parceiros - Renda)',
      url: '13QJNiWELP-HxznU1XW50VpUV5MF_BmFJ'
    }
  ]);

  const [pilarGovernanca, setPilarGovernanca] = useState([
    {
      title: 'Código de conduta e ética de colaboradores',
      url: '1bFe8JsRyErLEBMKoJPK9M6yMdGmtFQCo'
    },
    {
      title: 'Código de conduta e ética de parceiros e/ou fornecedor',
      url: '13yYJSV2XwS7GYXNzhtIlsTWIxzv-VcK1'
    },
    {
      title: 'Contratação de prestador',
      url: '11E8e3DiMHeNj-vMs7uMJVlittE2Mqzus'
    },
    {
      title: 'Conheça o seu parceiro',
      url: '1Fgk_bPnqnOz0A8eX-e5RROUthW5G1EOy'
    },
    {
      title: 'Prevenção a fraude',
      url: '1iZ95etYNwwShjN8VT4v_nJl0z6YVAVvG'
    },
    {
      title: 'Governança empresarial',
      url: '111dyJvHtatQ2gZBKg_ofDVHLRGKX3ZQc'
    },
  ]);

  const [pilarLGPD, setPilarLGPD] = useState([
    {
      title: 'Adequação a LGPD',
      url: '1CQlG4Lijmu8LgAvAV38TS0K7X4y25TtC'
    },
    {
      title: 'Relatório de impacto',
      url: '1LPOSY-SbWoYcrzs3SD2DH96W5LTea100'
    },
  ]);

  const [pilarRelacionamentoCliente, setPilarRelacionamentoCliente] = useState([
    {
      title: 'Público vulnerável',
      url: '1_k0We3RUoPJnun26Sfl5el57m5I9zbR6'
    },
    {
      title: 'Portabilidade de crédito',
      url: '1yxj7VbPukJi4gkhTVbygPwzWDTfrEv8N'
    },
    {
      title: 'Relacionamento com o cliente (Manual)',
      url: '13xE9sLmoWrJ40Sg8LSkd-FwwmPlQ_hCn'
    },
    {
      title: 'Relacionamento com o cliente (Política)',
      url: '1oHrY6raunjzaIkGo0Ig5vGnWXc3AegoG'
    },
    {
      title: 'Script consignado',
      url: '1P7CUH1QNnrGbzZL2FSzlwlMdWcyNG69X'
    },
    {
      title: 'Script renovação',
      url: '1EXfK1ZFjHRqKyA5W9pvooUQRAcOzUUy5'
    },
  ]);

  const [pilarTecnologia, setPilarTecnologia] = useState([
    {
      title: 'Gerenciamento de mudanças em TI',
      url: '1dynCSY-nJBkAjx1We4csBWJhz1EMKAcF'
    },
    {
      title: 'Cartilha de boas práticas - Segurança da informação',
      url: '13o9MgVHzz-q8zq3T-5dEr93a4fyhRSWZ'
    },
    {
      title: 'Gestão de acesso',
      url: '10UVjn0mlt219Urz7vomrvrLFcfPxw86S'
    },
    {
      title: 'Gestão de vulnerabilidade em TI',
      url: '1C1UPlS8RF6Nc05SzbTsAe3VNnn4mxiPO'
    },
    {
      title: 'Gestão de incidentes em TI',
      url: '1POlKY-a-8VJwlnTR0TmR_xCzcqOxwwH0'
    },
    {
      title: 'Política de uso do Antivírus',
      url: '13Y10uYvcNrFGJ-oT7eiDaZXEM8B12tLW'
    },
    {
      title: 'Armazenamento e backup de dados',
      url: '1kBfoitKnN9Q1SaCUqfFhbsm2PLVJE4SG'
    },
    {
      title: 'Acesso remoto',
      url: '1vSBU2HsnAz3OKSzPFqHUeby8d93_9wjE'
    },
    {
      title: 'Gerenciamento de mudanças de TI',
      url: '1uNLXyxABukmPB5KRvZtOIPzGr3fHGc3t'
    },
    {
      title: 'Uso de Firewall',
      url: '1MycW1J6emv97DBapADc_GtokTnugrbq1'
    },
    {
      title: 'Segurança da Informação',
      url: '1KBgKedge5LTmyOVbA__DPW_GoTl46Jwk'
    },
  ]);

  const [pilarTreinamento, setPilarTreinamento] = useState([
    {
      title: 'Treinamento e Desenvolvimento',
      url: '1GXm6IFpwQvVt7I3LyrV1GAHAMrR1CUh8'
    },
    {
      title: 'Treinamento Conexão Brasil LGPD',
      url: '158sdKeZj7bPRFhA0TU_nc0VXtFHZRCik'
    },
    {
      title: 'Treinamento Conexão Brasil PLDFT',
      url: '1llJDzygAAL5rUEPW0DwU0j7cYa_VlkYS'
    },
    {
      title: 'Treinamento Conexão Brasil Resumo contratual',
      url: '19NiHs8KHOG1TIQcn2UhBycm-wFjIXgTa'
    },
    {
      title: 'Treinamento Conexão Brasil Segurança da informação',
      url: '1O4_2pVoNxnaGG1eRY2xmitB_ReGJVP7z'
    },
    {
      title: 'Treinamento Conexão Brasil Tipos de Atendimento ao cliente',
      url: '1-yuKLQDXkADM6WzeEFl_MrW6zF70PiCW'
    },
    {
      title: 'Treinamento Conexão Brasil Público vulnerável',
      url: '1NKg8jsVQbsXatukGpYUo6Z7hZoxmiNXp'
    },
    {
      title: 'Treinamento Conexão Brasil Autorregulação e Resolução 4.935/21',
      url: '1NYWIM9MifxB_HCVw2Z8mEcmwi6oCSU6d'
    },
    {
      title: 'Treinamento Conexão Brasil Políticas, Normas e Procedimentos',
      url: '1xOGXkQlTb8wxMG-eqbyyBWyixAsNMSaR'
    },
    {
      title: 'Treinamento Conexão Brasil Prevenção a fraude',
      url: '1JkgIBHCIC7NnwhhDrDsxpBxWzNJ9pX_9'
    },
  ]);

  const [provas, setProvas] = useState([
    {
      title: 'PROVA - CÓDIGO DE ÉTICA',
      url: 'https://forms.gle/ha6oKu4anwLWZJ4h7'
    },
    {
      title: 'PROVA - LGPD',
      url: 'https://forms.gle/ngY9sFFmXYT9irLp7'
    },
    {
      title: 'PROVA - PREVENÇÃO A FRAUDE 2024',
      url: 'https://forms.gle/nKZumYMGQfxEVf576'
    },
    {
      title: 'PROVA - PÚBLICO VULNERÁVEL',
      url: 'https://forms.gle/WKQy4xK8X9Waar4k8'
    },
    {
      title: 'PROVA - RESUMO CONTRATUAL DE OPERAÇÕES DE CRÉDITO',
      url: 'https://forms.gle/3fWhLQuXfYHnk1fq5'
    },
    {
      title: 'PROVA - TIPOS E ATENDIMENTO AO CLIENTE',
      url: 'https://forms.gle/HRrqxxW6at1krJXi9'
    },
    {
      title: 'PROVA - PORTABILIDADE DE CRÉDITO',
      url: 'https://forms.gle/fQJfpevYR1jkbosJ6'
    },
    {
      title: 'PROVA - PLDFT',
      url: 'https://forms.gle/G1P6PQeg7bMa6qFfA'
    },
    {
      title: 'PROVA - RESOLUÇÃO E AUTORREGULAÇÃO',
      url: 'https://forms.gle/5go73qmENqJkQVwx6'
    },
  ]);

  return { section, portais, normativos, pilarGovernanca, pilarLGPD, pilarRelacionamentoCliente, pilarTecnologia, pilarTreinamento, provas }
}
