/* Função auxiliar de conversão de data.
*/
export const convertDate = (date) => {
    let dateParts = date.split('/');
    return new Date(dateParts[2], dateParts[1] - 1, dateParts[0]).toISOString().slice(0, 10);
}

/* Função auxiliar para converter moeda para float.
*/
export const filterMoeda = (arg) => {
    //checa se já não é um float
    if(typeof arg === 'string' && arg.length > 0)
        arg = parseFloat(arg.replace(/[^\d\,]/g, '').replace(',', '.'));
    if(arg === "" || typeof arg === 'undefined')
        arg = 0.00;
    return arg.toFixed(2)
}

/* Função auxiliar para remover caracteres que não sejam números.
*/
export const filterCharacters = (arg) => {
    return arg.replace(/[^\d]/g, '');
}

/* Função auxiliar de conversão de data.
*/
export const dateToString = (date) => {
    date = date.split('-');
    return date[2] + '/' + date[1] + '/' + date[0];
}
