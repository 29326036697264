import { useColorScheme } from '@mui/material/styles';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { IconButton } from '@mui/material';

export default function ColorModeSelect(props) {
  const { mode, setMode } = useColorScheme("system");
  if (!mode) {
    return null;
  }

  if (mode === "light") {
    document.getElementsByTagName("body")[0].style.backgroundImage = 'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 25%, 95%))'
  }
  else if (mode === "dark") {
    document.getElementsByTagName("body")[0].style.backgroundImage = 'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))'
  }
  return (
    mode === "light" ?
      <IconButton
        title='Tema Escuro'
        onClick={(e) => setMode("dark")}
        sx={{ backgroundColor: 'white' }}
        {...props}>
        <DarkModeIcon />
      </IconButton>
      :
      <IconButton title='Tema Claro' onClick={(e) => setMode("light")} {...props}>
        <LightModeIcon />
      </IconButton>
  );
}