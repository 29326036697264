import prop from 'prop-types';
import InputMask from 'react-input-mask';
import { FormLabel, Box, FormControl, TextField, Button } from '@mui/material';
import { useState } from 'react';

export const Input = ({ id, type, text, placeholder, value, onChange, mask, required, autofocus, onBlur, maxLength, formatChars, beforeMaskedValueChange, handleClickOpen }) => {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleBlur = (target) => {
        let check = onBlur(target);
        if (check) {
            setError(true);
            setErrorMessage(check);
        }
        else {
            setError(false);
            setErrorMessage('');
        }
    };

    return (
        <FormControl>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <FormLabel htmlFor={id} sx={{ textAlign: 'start' }}>{text}</FormLabel>
                {handleClickOpen &&
                    <Button
                        component="button"
                        onClick={handleClickOpen}
                        variant="body2"
                        sx={{ alignSelf: 'baseline' }}
                    >
                        Esqueceu sua senha?
                    </Button>
                }
            </Box>
            <InputMask mask={mask} maskChar={null}
                value={value} onChange={onChange} onBlur={(e) => handleBlur(e.target)}
                formatChars={formatChars} beforeMaskedValueChange={beforeMaskedValueChange}>
                {inputProps => <TextField
                    {...inputProps}
                    error={error}
                    helperText={errorMessage}
                    id={id}
                    type={type}
                    name={id}
                    value={value}
                    placeholder={placeholder}
                    autoFocus={autofocus}
                    required={required}
                    onChange={onChange}
                    fullWidth
                    variant="outlined"
                    color={error ? 'error' : 'primary'}
                    slotProps={{ htmlInput: { maxLength: { maxLength } } }}
                    sx={{ ariaLabel: { text } }}
                />}
            </InputMask>
        </FormControl>
    )
};

Input.defaultProps = {
    value: undefined,
    onChange: null,
    type: 'text',
    placeholder: '',
    autofocus: false,
    maxLength: null,
    mask: '',
    required: true,
    onBlur: () => false,
    formatChars: null,
    beforeMaskedValueChange: null,
    handleClickOpen: null,
}

Input.propTypes = {
    id: prop.string.isRequired,
    type: prop.string,
    text: prop.string.isRequired,
    placeholder: prop.string,
    value: prop.string,
    onChange: prop.func,
    maxLength: prop.oneOfType([prop.string, prop.number]),
    autofocus: prop.bool,
    mask: prop.any,
    required: prop.bool,
    onBlur: prop.func,
    formatChars: prop.object,
    beforeMaskedValueChange: prop.func,
    handleClickOpen: prop.func,
}
