import { Typography } from '@mui/material';
import prop from 'prop-types';

export const Info = ({ title, text }) => (
    <Typography>
        <strong>{title} </strong>
        {text}
    </Typography>
);

Info.propTypes = {
    title: prop.string.isRequired,
    text: prop.oneOfType([prop.string, prop.number]).isRequired,
}