import { useEffect } from 'react';
import { Input } from '../../components/Input';
import { LendingHelper } from './helper';
import { Card, Box, CardContent, Typography, Divider, TableContainer, Table, TableHead, TableCell, TableRow, TableBody, CardActions, Button, Paper } from '@mui/material';
import { SelectField } from '../../components/SelectField';

export const Lending = () => {
  const { searchFuncionarios, searchEmpresas, handleEmpresa, handleFuncionario, addItemToTable, generateComodato, items, empresas, funcionarios, empresa, funcionario } = LendingHelper();

  useEffect(() => {
    searchFuncionarios();
    searchEmpresas();
  }, [searchFuncionarios, searchEmpresas]);

  return (
    <Card sx={{ minWidth: 275, height: '100%' }}>
      <Box component='form' onSubmit={addItemToTable} noValidate>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography gutterBottom variant="h2" component="div">
              Termo de Comodato
            </Typography>
          </Box>
          <SelectField id="empresas" text="Empresas" value={empresa} setoption={handleEmpresa} options={empresas} />
          <SelectField id="funcionarios" text="Funcionários" value={funcionario} setoption={handleFuncionario} options={funcionarios} />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginY: 2,
            }}
          >
            <Input id="bem" type="text" text="Bem" />
            <Divider orientation="vertical" variant="middle" flexItem sx={{ marginX: 2 }} />
            <Input id="modelo" type="text" text="Modelo" />
            <Divider orientation="vertical" variant="middle" flexItem sx={{ marginX: 2 }} />
            <Input id="serial" type="text" text="Serial" />
            <Divider orientation="vertical" variant="middle" flexItem sx={{ marginX: 2 }} />
            <Input id="status" type="text" text="Status" />
            <Divider orientation="vertical" variant="middle" flexItem sx={{ marginX: 2 }} />
            <Input id="observacao" type="text" text="Observação" />
          </Box>
        </CardContent>
        {items.length > 0 &&
          <TableContainer component={Paper} sx={{ marginY: 5 }}>
            <Table sx={{ minWidth: 650 }} aria-label="Lista de Comodatos">
              <TableHead>
                <TableRow>
                  <TableCell>Bem</TableCell>
                  <TableCell align="right">Modelo</TableCell>
                  <TableCell align="right">Serial</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Observação</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((row, index) => (
                  <TableRow
                    key={index}
                  >
                    <TableCell component="th" scope="row">{row.bem}</TableCell>
                    <TableCell align="right">{row.modelo}</TableCell>
                    <TableCell align="right">{row.serial}</TableCell>
                    <TableCell align="right">{row.status}</TableCell>
                    <TableCell align="right">{row.observacao}</TableCell>
                    <TableCell align="right">{row.operations}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
        <CardActions sx={{ float: 'right', alignItems: 'flex-end' }}>
          <Button type="submit" variant="contained" id="addItem" title='Adicionar Item'>
            Adicionar Item
          </Button>
          <Button id="btnSave" onClick={(e) => generateComodato(e)} title='Gerar Comodato'>Gerar Comodato</Button>
        </CardActions>
      </Box>
    </Card >
  );
}
