import { Box, Button, useTheme } from '@mui/material';
import { CheckCredentials } from '../CheckCredentials/index.jsx';
import { SignInContainer, Card } from '../../styles/styles.js';

import { Input } from '../../components/Input';
import logo from '../../img/logo.png';
import logoAlt from "../../img/logo-alt.png";
import { LoginHelper } from './helper';
import ColorModeSelect from '../../ColorModeSelect.js';

export const Login = () => {
  const { handleClickOpen, handleClose, open, login } = LoginHelper();

  const theme = useTheme();

  return (
    <SignInContainer direction="column" justifyContent="center">
      <ColorModeSelect sx={{ position: 'fixed', top: '1rem', right: '1rem' }} />
      <Card variant="outlined">
        <img className="mb-5 logo" src={theme.palette.mode === 'light' ? logoAlt : logo} alt="Conexão Brasil Coban" style={{ alignSelf: 'center' }} />
        <Box
          component="form"
          onSubmit={login}
          noValidate
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: 2,
          }}
        >
          <Input id="username" text="Login" placeholder='123.456.789-00' autofocus={true} />
          <Input id="password" type='password' text="Senha" placeholder='••••••' handleClickOpen={handleClickOpen} maxLength={32} />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ marginY: 2 }}
          >
            Entrar
          </Button>
        </Box>
      </Card>
      <CheckCredentials open={open} handleClose={handleClose} />
    </SignInContainer>
  );
}
