import { validate } from "./validate";

export const calculaDigitoMod11 = (dado, numDig, limMult, x10) => {

    var mult, soma, i, n, dig;

    if (!x10) numDig = 1;
    for (n = 1; n <= numDig; n++) {
        soma = 0;
        mult = 2;
        for (i = dado.length - 1; i >= 0; i--) {
            soma += (mult * parseInt(dado.charAt(i)));
            if (++mult > limMult) mult = 2;
        }
        if (x10) {
            dig = ((soma * 10) % 11) % 10;
        } else {
            dig = soma % 11;
            if (dig === 10) dig = "X";
        }
        dado += (dig);
    }
    return dado.substr(dado.length - numDig, numDig);
}

export const validaCPF = (strCPF) => {
    strCPF = strCPF.replace(/\D/g, "");
    var soma;
    var resto;
    soma = 0;
    if (strCPF === "00000000000" ||
        strCPF === "11111111111" ||
        strCPF === "22222222222" ||
        strCPF === "33333333333" ||
        strCPF === "44444444444" ||
        strCPF === "55555555555" ||
        strCPF === "66666666666" ||
        strCPF === "77777777777" ||
        strCPF === "88888888888" ||
        strCPF === "99999999999")
        return false;

    for (let i = 1; i <= 9; i++) soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if ((resto === 10) || (resto === 11)) resto = 0;
    if (resto !== parseInt(strCPF.substring(9, 10))) return false;

    soma = 0;
    for (let i = 1; i <= 10; i++) soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if ((resto === 10) || (resto === 11)) resto = 0;
    if (resto !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
}

export const checkCEP = (input) => {
    return input.value.length === 9 ? false : "CEP inválido";
}

export const checkChaveJ = (input) => {
    return input.value.length === 8 ? false : "ChaveJ inválida";
}

export const checkDate = (input) => {
    return input.value.length === 10 ? false : "Data inválida";
}

export const checkCPF = (input) => {
    return validaCPF(input.value) && input.value.length === 14 ? false : "CPF inválido";
}

export const checkAgencia = (input) => {
    return input.value.length === 6 && calculaDigitoMod11(input.value.slice(0, 4), 1, 9, true) === input.value.slice(5, 6) ? false : "Agência inválida";
}

export const checkContaBanco = (input) => {
    return input.value.match(/^\d{2,9}-\d{1}$/) ? false : "Conta inválida";
}

export const checkEmail = (input) => {
    return input.value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/) ? false : "Email inválido";
}

export const checkPhone = (input) => {
    return input.value.length === 15 ? false : "Telefone inválido";
}

export const checkEmpty = (input) => {
    return input.value ? false : "Campo obrigatório está vazio";
}
