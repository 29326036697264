import { useCallback, useState, useReducer } from 'react';
import { useCookies } from 'react-cookie';
import { saveAs } from "file-saver";
import axios from 'axios';
import { BACKEND_URL } from '../../util/config';
import { error, success, successWithReload } from '../../util/alert';
import { checkAuthenticity } from '../../util/auth';
import Swal from 'sweetalert2';
import { filterCharacters, filterMoeda } from '../../util/filter';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import BlockIcon from '@mui/icons-material/Block';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import InfoIcon from '@mui/icons-material/Info';
import KeyIcon from '@mui/icons-material/Key';
import LabelIcon from '@mui/icons-material/Label';
import { formatterMoeda } from '../../util/formatter';

export const TableFuncHelper = () => {
  const [cookie, setCookie] = useCookies(['auth']);
  const [id, setId] = useState(0);
  const [rows, setRows] = useState([]);
  const [show, setShow] = useState(false);
  const [funcionario, setFuncionario] = useState(null);
  const [cargo, setCargo] = useState('agente');
  const [empresa, setEmpresa] = useState(0);
  const [empresaChaveJ, setEmpresaChaveJ] = useState(0);
  const [chaveJ, setChaveJ] = useState(0);
  const [empresas, setEmpresas] = useState([]);
  const [custo, setCusto] = useState('');
  const [salario, setSalario] = useState('');
  const [ajuda, setAjuda] = useState('');
  const [adiantamento, setAdiantamento] = useState('');
  const [rendimento, setRendimento] = useState('');
  const [internet, setInternet] = useState('');
  const [aluguel, setAluguel] = useState('');

  const [showBackDrop, setShowBackDrop] = useState(true);
  const [showFinance, setShowFinance] = useState(false);
  const [showChangeCargo, setShowChangeCargo] = useState(false);
  const [showChooseDownload, setShowChooseDownload] = useState(false);
  const [showCreateChaveJ, setShowCreateChaveJ] = useState(false);
  const [funcionarioChangeCargo, setFuncionarioChangeCargo] = useState('');
  const [funcionarioCreateChaveJ, setFuncionarioCreateChaveJ] = useState('');
  const [chaveJFinance, setChaveJFinance] = useState('');
  const [lockFinance, setLockFinance] = useState(true);

  const [cargoChanged, setCargoChanged] = useState('');
  const [supervisor, setSupervisor] = useState('');
  const [atuacao, setAtuacao] = useState('');

  const [supervisores, setSupervisores] = useState([]);
  const [listCargos, setListCargos] = useState([
    { value: 'agente', label: 'Agente' },
    { value: 'supervisor', label: 'Supervisor' },
    { value: 'administrador', label: 'Administrador' }
  ]);
  const [listCusto, setListCusto] = useState([
    { value: 'true', label: 'Sim' },
    { value: 'false', label: 'Não' }
  ]);

  const [filter, setFilter] = useState('');
  const [textInfo, setTextInfo] = useState('Carregando...');

  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  const [columns, setColumns] = useState([
    {
      field: 'nome',
      headerName: 'Nome',
      flex: 0.7,
      cellClassName: 'align-middle-table-cell',
    },
    {
      field: 'cpf',
      headerName: 'CPF',
      flex: 0.3,
      valueFormatter: (value) => (value ? value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : ''),
      cellClassName: 'align-middle-table-cell',
    },
    {
      field: 'operations_func',
      type: 'actions',
      headerName: '',
      flex: 0.5,
      filterable: false,
      getApplyQuickFilterFn: undefined,
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={<InfoIcon />}
            label="Mais informações"
            title="Mais informações"
            onClick={(e) => showData(row.rule_id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<KeyIcon />}
            label="Criar ChaveJ"
            title="Criar ChaveJ"
            onClick={(e) => handleShowCreateChaveJ(row.func_id, row.nome)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<LabelIcon />}
            label="Editar Cargo"
            title="Editar Cargo"
            onClick={(e) => getFuncionario(row.cargo, row.rule_id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Apagar Funcionário"
            title="Apagar Funcionário"
            onClick={(e) => confirmDeleteFuncionario(row.nome, row.func_id)}
            color="inherit"
          />]
      }
    },
    {
      field: 'chavej',
      headerName: 'ChaveJ',
      flex: 0.3,
    },
    {
      field: 'ativo',
      headerName: 'Ativo',
      flex: 0.3,
      valueFormatter: (value) => (value ? 'Ativo' : 'Inativo'),
      rowSpanValueGetter: (value, row) => {
        return row;
      },
    },
    {
      field: 'operations_chavej',
      type: 'actions',
      headerName: '',
      filterable: false,
      getApplyQuickFilterFn: undefined,
      flex: 0.4,
      getActions: ({ row }) => {
        let actions = [
          <GridActionsCellItem
            icon={<CurrencyExchangeIcon />}
            label="Modificar Financeiro"
            title="Modificar Financeiro"
            onClick={(e) => handleShowFinance(row.id, row.nome)}
            color="inherit"
          />,]

        if (row.ativo)
          actions.push(
            <GridActionsCellItem
              icon={<BlockIcon />}
              label="Bloquear"
              title="Bloquear"
              onClick={(e) => block(row.id, row.chavej, row.nome)}
              color="inherit"
            />)
        else
          actions.push(
            <GridActionsCellItem
              icon={<HowToRegIcon />}
              label="Reativar"
              title="Reativar"
              onClick={(e) => unblock(row.id, row.chavej, row.nome)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Apagar"
              title="Apagar"
              onClick={(e) => confirmDeleteChaveJ(row.id, row.chavej, row.nome, row.func_id)}
              color="inherit"
            />)

        return actions
      }
    }
  ]);

  /*Função para procurar supervisores dinamicamente. */
  const searchSupervisoresAndAdministradores = useCallback(async () => {
    await axios
      .get(BACKEND_URL + "supervisores_validos/",
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
      .then(response => {
        let supervisoresData = response.data.supervisores;
        let supervisores = [];

        supervisoresData.forEach(function (supervisor) {
          supervisores.push({
            value: supervisor.cpf,
            label: supervisor.nome,
            is_admin: supervisor.is_admin
          })
        })
        setSupervisores(supervisores);
      })
      .catch(erro => {
        console.log(erro);
        checkAuthenticity(erro.response.status);
      })
  }, [cookie])

  /*Função para procurar empresas dinamicamente. */
  const searchEmpresas = useCallback(async () => {
    await axios
      .get(BACKEND_URL + "empresas/",
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
      .then(response => {
        let empresasData = response.data.empresas;
        let empresas = [{ value: 0, label: "Todas" }];

        empresasData.forEach(empresa => {
          empresas.push({
            value: empresa.id,
            label: empresa.nome,
          })
        })
        setEmpresas(empresas);
      })
      .catch(erro => {
        console.log(erro);
        checkAuthenticity(erro.response.status);
      })
  }, [cookie])

  const updateCargo = () => {
    let jsonData = {
      new_c: cargoChanged,
      old_c: cargo,
      data: {
        func_id: funcionarioChangeCargo.func_id,
      }
    }

    if (cargoChanged === 'agente') {
      let supervisorSelected = supervisores.find((selected, index, array) => selected.value === supervisor);
      jsonData.data.supervisor = filterCharacters(supervisorSelected.value);
      jsonData.data.is_admin = supervisorSelected.is_admin;
    } else if (cargoChanged === 'supervisor') {
      jsonData.data.super_supervisor = supervisor;
    }

    //adiciona a agência caso não seja um administrador
    if (cargoChanged === 'agente' || cargoChanged === 'supervisor') {
      jsonData.data.ag_atuacao = atuacao;
    }

    axios
      .post(BACKEND_URL + 'funcionario/mudanca_de_cargo', jsonData,
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
      .then(response => {
        successWithReload('Cargo atualizado', 'Mudança de cargo realizada com sucesso!');
      })
      .catch(erro => {
        if (erro.response.status === 409)
          error('Operação bloqueada', 'É necessário que nenhum outro funcionário esteja vinculado à este. Verifique e tente novamente mais tarde.');
        else
          error('Erro', 'Aconteceu um erro inesperado, tente novamente. Caso o problema persista, entre em contato com o suporte.');
        console.log(erro);
        checkAuthenticity(erro.response.status);
      })
  };

  const getFuncionario = (newCargo, id) => {
    axios
      .get(BACKEND_URL + newCargo + "/" + id,
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
      .then(response => {
        var funcionarioData = response.data;
        setFuncionarioChangeCargo(funcionarioData);
        setAtuacao(funcionarioData.ag_atuacao);
        setSupervisor(funcionarioData.supervisor_cpf);
        setShowChangeCargo(prev => true);
      })
      .catch(erro => {
        error('Erro', 'Não foi possível carregar as informações.')
        console.log(erro);
        setTextInfo('Não há funcionários cadastrados');
        checkAuthenticity(erro.response.status);
      })
  };

  const showData = (id) => {
    setId(id);
    setShow(true);
    forceUpdate();
  };

  const confirmDeleteChaveJ = (id, chavej, name, func_id) => {
    Swal.fire({
      title: 'Apagar ChaveJ',
      text: `Deseja realmente excluir a Chave ${chavej} vinculada à ${name}?`,
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed)
        deleteChaveJ(id, chavej, name, func_id);
    })
  };

  const createChaveJ = (e) => {
    e.preventDefault();

    if (funcionarioCreateChaveJ && chaveJ && parseInt(empresaChaveJ) !== 0)
      axios
        .post(BACKEND_URL + "chavej/",
          {
            funcionario: funcionarioCreateChaveJ.id,
            chavej: chaveJ,
            empresa: empresaChaveJ,
            dados_financeiros: {
              custo: custo,
              salario: filterMoeda(salario),
              aj_custo: filterMoeda(ajuda),
              adiantamento: filterMoeda(adiantamento),
              rendimento: filterMoeda(rendimento),
              internet: filterMoeda(internet),
              aluguel: filterMoeda(aluguel)
            }
          },
          {
            headers: {
              Authorization: ["Bearer", cookie.access_token].join(" "),
            },
          })
        .then(response => {
          successWithReload('ChaveJ', `ChaveJ foi criada com sucesso!`);
        })
        .catch(erro => {
          error('Erro', 'Não foi possível carregar as informações.');
          console.log(erro);
          checkAuthenticity(erro.response.status);
        })
  };

  const deleteChaveJ = (id, chavej, name, func_id) => {
    axios
      .delete(BACKEND_URL + "chavej/" + id + "/" + func_id,
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
      .then(response => {
        successWithReload('ChaveJ removida', `Chave ${chavej} vinculada à ${name} foi removida com sucesso!`);
      })
      .catch(erro => {
        error('Erro', erro.response.data.error);
        console.log(erro);
        checkAuthenticity(erro.response.status);
      })
  };

  const block = (id, chavej, nome) => {
    Swal.fire({
      title: 'Bloquear ChaveJ',
      text: `Deseja realmente bloquear ChaveJ ${chavej} de ${nome}?`,
      showCancelButton: true,
      confirmButtonText: 'Bloquear',
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(BACKEND_URL + "chavej/desativar/" + id,
            {
              headers: {
                Authorization: ["Bearer", cookie.access_token].join(" "),
              },
            })
          .then(response => {
            document.getElementById('refresh').click();
            success('Sucesso', `ChaveJ ${chavej} de ${nome} inativada com sucesso!`);
          })
          .catch(erro => {
            error('Erro', 'Aconteceu um erro inesperado, tente novamente. Caso o problema persista, entre em contato com o suporte.');
            console.log(erro);
            checkAuthenticity(erro.response.status);
          })
      }
    })
  };

  const unblock = (id, chavej, nome) => {
    axios
      .get(BACKEND_URL + "chavej/ativar/" + id,
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
      .then(response => {
        document.getElementById('refresh').click();
        success('Sucesso', `A ChaveJ ${chavej} de ${nome} foi reativada com sucesso!`);
      })
      .catch(erro => {
        error('Erro', 'Aconteceu um erro inesperado, tente novamente. Caso o problema persista, entre em contato com o suporte.');
        console.log(erro);
        checkAuthenticity(erro.response.status);
      })
  };

  const confirmDeleteFuncionario = (name, func_id) => {
    Swal.fire({
      title: 'Apagar Funcionário',
      text: `Deseja realmente excluir ${name}?`,
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed)
        deleteFuncionario(name, func_id);
    })
  };

  const deleteFuncionario = (name, func_id) => {
    Swal.fire({
      title: 'Removendo',
      allowEscapeKey: false,
      allowOutsideClick: false,
      timerProgressBar: true,
      didOpen: () => {
        setShowChooseDownload(false);
        Swal.showLoading();
        axios
          .delete(BACKEND_URL + cargo + "/" + func_id,
            {
              headers: {
                Authorization: ["Bearer", cookie.access_token].join(" "),
              },
            })
          .then(response => {
            Swal.close();
            successWithReload('Sucesso', `${name} removido(a) com sucesso!`);
          })
          .catch(erro => {
            error('Erro', erro.response.data.error);
            console.log(erro);
            checkAuthenticity(erro.response.status);
          })
      }
    })
  };

  const updateFinance = (e) => {
    e.preventDefault();

    axios
      .post(BACKEND_URL + "dados_financeiros/" + chaveJFinance.chavej,
        {
          custo: custo,
          salario: filterMoeda(salario),
          aj_custo: filterMoeda(ajuda),
          adiantamento: filterMoeda(adiantamento),
          rendimento: filterMoeda(rendimento),
          internet: filterMoeda(internet),
          aluguel: filterMoeda(aluguel)
        },
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
      .then(response => {
        successWithReload('Dados Financeiros', `Dados Financeiros modificados com sucesso!`);
      })
      .catch(erro => {
        error('Erro', 'Não foi possível carregar as informações.');
        console.log(erro);
        checkAuthenticity(erro.response.status);
      })
  };

  const getFuncionarios = async (newCargo) => {
    await axios
      .get(BACKEND_URL + newCargo + "/",
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
      .then(response => {
        let funcionarios = response.data.agentes || response.data.supervisores || response.data.administradores;
        let rows = [];

        funcionarios.forEach(function (funcionario) {
          funcionario.chavej.forEach(function (chavej) {
            let row = {
              id: chavej.id,
              nome: funcionario.nome,
              cpf: funcionario.cpf,
              chavej: chavej.chavej,
              ativo: chavej.ativo,
              func_id: funcionario.func_id,
              rule_id: funcionario.id,
              cargo: newCargo
              // empresa: chavej.empresa
            }
            rows.push(row);
          })
        })
        setRows(rows);
        setShowBackDrop(prev => false);
      })
      .catch(erro => {
        console.log(erro);
        checkAuthenticity(erro.response.status);
      })
  };

  const getFinance = (chavej) => {
    axios
      .get(BACKEND_URL + "dados_financeiros/" + chavej,
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
      .then(response => {
        setCusto(response.data.custo.toString())
        setSalario(formatterMoeda(response.data.salario));
        setAjuda(formatterMoeda(response.data.aj_custo));
        setAdiantamento(formatterMoeda(response.data.adiantamento));
        setRendimento(formatterMoeda(response.data.rendimento));
        setInternet(formatterMoeda(response.data.internet));
        setAluguel(formatterMoeda(response.data.aluguel));
      })
      .catch(erro => {
        error('Erro', 'Não foi possível carregar as informações.');
        console.log(erro);
        checkAuthenticity(erro.response.status);
      })
  };

  const refresh = () => {
    getFuncionarios(cargo || 'agente');
  };

  const handleCargo = (newCargo) => {
    setCargo(newCargo);
    getFuncionarios(newCargo);
    forceUpdate();
  };

  const handleShowCreateChaveJ = (idFunc, NomeFunc) => {
    setCusto('');
    setSalario('');
    setAjuda('');
    setAdiantamento('');
    setRendimento('');
    setInternet('');
    setAluguel('');
    setChaveJ('');
    setEmpresaChaveJ(0);
    setFuncionarioCreateChaveJ({ id: idFunc, nome: NomeFunc });
    setShowCreateChaveJ(true);
  };

  const handleShowFinance = (chavej, NomeFunc) => {
    getFinance(chavej);
    setChaveJFinance({ chavej: chavej, nome: NomeFunc })
    setShowFinance(true);
  };

  const loadings = (activity) => {
    Swal.fire({
      title: 'Baixando',
      allowEscapeKey: false,
      allowOutsideClick: false,
      timerProgressBar: true,
      didOpen: () => {
        setShowChooseDownload(false);
        Swal.showLoading();
        download(activity)
          .then(response => {
            saveAs(response.data, "Relatório(" + new Date().toLocaleDateString().replace("_", "/") + ").xlsx");
            Swal.close();
          })
          .catch(erro => {
            error('Erro', 'Não foi possível baixar o arquivo.')
            console.log(erro);
            checkAuthenticity(erro.response.status);
          })
      }
    })
  };

  const download = (activity) => {
    return axios
      .get(BACKEND_URL + `funcionarios/export/${activity}`,
        {
          responseType: "blob",
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
  }

  return { searchSupervisoresAndAdministradores, searchEmpresas, handleCargo, refresh, loadings, updateCargo, createChaveJ, updateFinance, showFinance, setShowFinance, showChooseDownload, setShowChooseDownload, showChangeCargo, setShowChangeCargo, show, setShow, showCreateChaveJ, setShowCreateChaveJ, cargoChanged, funcionarioChangeCargo, funcionarioCreateChaveJ, cookie, empresas, supervisores, empresa, empresaChaveJ, supervisor, atuacao, chaveJ, funcionario, rows, textInfo, filter, custo, salario, ajuda, adiantamento, rendimento, internet, aluguel, lockFinance, chaveJFinance, setEmpresa, setCargoChanged, setSupervisor, setAtuacao, setChaveJ, setEmpresaChaveJ, setFilter, setCusto, setSalario, setAjuda, setAdiantamento, setRendimento, setInternet, setAluguel, setLockFinance, columns, showBackDrop, setShowBackDrop, id, cargo, listCargos, listCusto }
}
