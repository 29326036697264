import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { Default } from '../../components/Default';
import { FormAddFunc } from '../FormAddFunc';
import { AddDocument } from '../AddDocument';
import { AddCertificate } from '../AddCertificate';
import { Dashboard } from '../Dashboard';
import { Page404 } from '../Page404';
import { TableFunc } from '../TableFunc';
import { Login } from '../Login';
import PrivateRoute from '../../components/PrivateRoute';
import AppTheme from '../../AppTheme';

import Swal from 'sweetalert2';
import { Redefine } from '../Redefine';
import { Lending } from '../Lending';
import { Learning } from '../Learning';

export const NavSideContext = React.createContext();

export const Home = (props) => {
  const toggle = useRef(null);
  const nav = useRef(null);
  const headerpd = useRef(null);
  const [showInfo, setShowInfo] = useState(false);

  const [cookies, setCookie, removeCookie] = useCookies(['auth']);

  const showSideBar = () => {
    // show navbar
    nav.current.classList.toggle('show-nav')
    // change icon
    toggle.current.classList.toggle('bx-x')
    // add padding to body
    document.body.classList.toggle('body-pd')
    // add padding to header
    headerpd.current.classList.toggle('body-pd')
  };

  const showActive = () => {
    const links = document.querySelectorAll('.sidebar-link')

    links.forEach(
      link => link.addEventListener('click', function (e) {
        if (links) {
          links.forEach(l =>
            l.classList.remove('active')
          )
          this.classList.add('active')
        }
      })
    )
  }

  const removeEvents = () => {
    const links = document.querySelectorAll('.sidebar-link')

    links.forEach(
      link => link.removeEventListener('click', function (e) {
        if (links) {
          links.forEach(l =>
            l.classList.remove('active'),
          )
          this.classList.add('active')
        }
      })
    )
  }

  const onClickSignOut = (e) => {
    e.preventDefault();

    Swal.fire({
      title: 'Sair',
      text: "Realmente deseja sair?",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sair'
    }).then((result) => {
      if (result.isConfirmed) {
        removeCookie('access_token', { path: '/' });
        window.location.href = '/login';
      }
    })
  };

  const onClickShowInfo = (e) => {
    e.preventDefault();

    setShowInfo(!showInfo);
  };

  useEffect(() => {
    showActive();
    return removeEvents;
  }, []);

  return (
    <AppTheme {...props}>
      <NavSideContext.Provider value={[toggle, nav, headerpd, showSideBar]}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} exact />
            <Route path="/redefine/:token" element={<Redefine />} exact />
            <Route element={<PrivateRoute />}>
              <Route path="/" element={
                <Default toggle={toggle} headerpd={headerpd} showSideBar={showSideBar} onClickShowInfo={onClickShowInfo} showInfo={showInfo} setShowInfo={setShowInfo} onClickSignOut={onClickSignOut} nav={nav} >
                  <Dashboard />
                </Default>
              } exact />
              <Route path="/form" element={
                <Default toggle={toggle} headerpd={headerpd} showSideBar={showSideBar} onClickShowInfo={onClickShowInfo} showInfo={showInfo} setShowInfo={setShowInfo} onClickSignOut={onClickSignOut} nav={nav} >
                  <FormAddFunc />
                </Default>
              } exact />
              <Route path="/table" element={
                <Default toggle={toggle} headerpd={headerpd} showSideBar={showSideBar} onClickShowInfo={onClickShowInfo} showInfo={showInfo} setShowInfo={setShowInfo} onClickSignOut={onClickSignOut} nav={nav} >
                  <TableFunc />
                </Default>
              } exact />
              <Route path="/doc" element={
                <Default toggle={toggle} headerpd={headerpd} showSideBar={showSideBar} onClickShowInfo={onClickShowInfo} showInfo={showInfo} setShowInfo={setShowInfo} onClickSignOut={onClickSignOut} nav={nav} >
                  <AddDocument />
                </Default>
              } exact />
              <Route path="/lending" element={
                <Default toggle={toggle} headerpd={headerpd} showSideBar={showSideBar} onClickShowInfo={onClickShowInfo} showInfo={showInfo} setShowInfo={setShowInfo} onClickSignOut={onClickSignOut} nav={nav} >
                  <Lending />
                </Default>
              } exact />
              <Route path="/certificate" element={
                <Default toggle={toggle} headerpd={headerpd} showSideBar={showSideBar} onClickShowInfo={onClickShowInfo} showInfo={showInfo} setShowInfo={setShowInfo} onClickSignOut={onClickSignOut} nav={nav} >
                  <AddCertificate />
                </Default>
              } exact />
              <Route path="/learning/*" element={
                <Default toggle={toggle} headerpd={headerpd} showSideBar={showSideBar} onClickShowInfo={onClickShowInfo} showInfo={showInfo} setShowInfo={setShowInfo} onClickSignOut={onClickSignOut} nav={nav} >
                  <Learning />
                </Default>
              } exact />
              <Route path="*" element={
                <Default toggle={toggle} headerpd={headerpd} showSideBar={showSideBar} onClickShowInfo={onClickShowInfo} showInfo={showInfo} setShowInfo={setShowInfo} onClickSignOut={onClickSignOut} nav={nav} >
                  <Page404 />
                </Default>
              } />
            </Route>
            <Route path="*" element={<Page404 />} exact />
          </Routes>
        </BrowserRouter>
      </NavSideContext.Provider >
    </AppTheme>
  );
}
