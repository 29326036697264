import { Checkbox, FormControlLabel } from '@mui/material';
import prop from 'prop-types';

export const CheckButton = ({ id, text, value, onSelect, disabled }) => (
    <FormControlLabel disabled={disabled} control={<Checkbox
        id={id}
        checked={value}
        onChange={(e) => onSelect(!value)}
        inputProps={{ 'aria-label': text }} />}
        label={text} />
);

CheckButton.defaultProps = {
    disabled: false
}

CheckButton.propTypes = {
    id: prop.string.isRequired,
    text: prop.string.isRequired,
    value: prop.bool.isRequired,
    onSelect: prop.func.isRequired,
    disabled: prop.bool
}
