import { useState } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { BACKEND_URL } from '../../util/config';
import { error, successWithReload } from '../../util/alert';
import { checkAuthenticity } from '../../util/auth';
import Swal from 'sweetalert2';

export const MyInfoHelper = () => {
  const [cookie, setCookie] = useCookies(['auth']);
  const [funcionario, setFuncionario] = useState(null);

  const showData = (id, cargo) => {
    axios
      .get(BACKEND_URL + cargo + "/" + id,
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
      .then(response => {
        var funcionarioData = response.data;
        setFuncionario(funcionarioData);
      })
      .catch(erro => {
        error('Erro', 'Não foi possível carregar as informações.')
        console.log(erro);
        checkAuthenticity(erro.response.status);
      })
  };

  const getSexo = (funcSexo) => {
    if (funcSexo === 'Masc')
      return 'Masculino';
    else if (funcSexo === 'Fem')
      return 'Feminino';
    else
      return '-';
  }

  const deleteCert = (e, id, nome) => {
    e.preventDefault();

    Swal.fire({
      title: 'Remover Certificado',
      text: `Deseja realmente remover certificado ${nome}?`,
      showCancelButton: true,
      confirmButtonText: 'Remover',
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(BACKEND_URL + "funcionario/certificado/" + id,
            {
              headers: {
                Authorization: ["Bearer", cookie.access_token].join(" "),
              },
            })
          .then(response => {
            successWithReload('Removido', `Certificado ${nome} removido com sucesso!`)
          })
          .catch(erro => {
            error('Erro', 'Não foi possível carregar as informações.')
            console.log(erro);
            checkAuthenticity(erro.response.status);
          })
      }
    })
  }

  return { cookie, showData, getSexo, funcionario, deleteCert }
}
