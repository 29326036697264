import { Input } from '../../components/Input';
import '../../styles/style.css';
import logo from '../../img/logo.png';
import { RedefineHelper } from './helper';

export const Redefine = () => {
  const { createNewPassword, password, confirmPassword, setPassword, setConfirmPassword } = RedefineHelper();

  return (
    <div className="box-centered mx-auto">
      <img className="mb-5 logo" src={logo}
        alt="Conexão Brasil Coban" />
      <form id="check-credentials-form" className="mt-3">
        <Input id="password" type="password" text="Senha" value={password} onChange={(e) => setPassword(e.target.value)} onKeyPress={(e) => { if (e.key === "Enter") createNewPassword(e) }} autoFocus={true} />
        <Input id="confirmPassword" type="password" text="Confirmar Senha" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} onKeyPress={(e) => { if (e.key === "Enter") createNewPassword(e) }} />
        <button id="btnSubmit" className="btn mt-5 button" type="submit" onClick={createNewPassword} title='Enviar'>Enviar</button>
      </form>
    </div>
  );
}
