import prop from 'prop-types';
import { Link } from 'react-router-dom';

import logo from '../../img/logo.png';
import { SideBarData } from './data';
import { useCookies } from 'react-cookie';
import { IconExpandSidebar } from '../../styles/styles';
import { useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export const SideBar = ({ sidebar, toggle, onClick }) => {
  const [cookies, setCookie] = useCookies(['auth']);
  const [arrowLeft, setArrowLeft] = useState(false);

  const openSidebar = () => {
    setArrowLeft(!arrowLeft);
    onClick();
  }

  document.getElementsByTagName("body")[0].style.paddingLeft = 'calc(var(--nav-width) + 1rem)'

  return (
    <div className="sidebar" id="nav-bar" ref={sidebar}>
      <nav className="nav">
        <div>
          <Link to="/" className="sidebar-logo" style={{ paddingLeft: ".5rem" }}>
            <img src={logo} className="logo-nav" alt="Conexão Brasil Coban" />
          </Link>
          <div className="nav_list">
            {SideBarData.map(link => (
              !link.block.includes(cookies.tipo) &&
              <Link to={link.path} className="sidebar-link" key={link.path}>
                {link.icon}
                <span className="sidebar-name">{link.title}</span>
              </Link>
            ))}
          </div>
        </div>
        <IconExpandSidebar sx={{ background: 'transparent !important', border: 0, marginBottom: 1.25, marginLeft: 1.25 }} type="button" ref={toggle} onClick={() => openSidebar()}>
          {arrowLeft ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
        </IconExpandSidebar>
      </nav>
    </div>
  );
};

SideBar.propTypes = {
  toggle: prop.object.isRequired,
  sidebar: prop.object.isRequired,
  onClick: prop.func.isRequired,
}
