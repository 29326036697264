import { useEffect } from 'react';
import { Input } from '../../components/Input';
import { SelectField } from '../../components/SelectField';
import { DateMask, formatChars } from '../../util/mask';
import { AddCertificateHelper } from './helper.js';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Typography, Box, Button, Card, CardContent, CardActions, Divider } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export const AddCertificate = () => {
	const { searchFuncionarios, downloadRelatorio, addItemToTable, addCertificates, funcionarios, funcionario, items, setFuncionario } = AddCertificateHelper();

	useEffect(() => {
		searchFuncionarios();
	}, [searchFuncionarios]);

	return (
		<Card sx={{ minWidth: 275, height: '100%' }}>
			<Box component='form' onSubmit={addItemToTable} noValidate>
				<CardContent>
					<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Typography gutterBottom variant="h2" component="div">
							Certificações Regulatórias
						</Typography>
						<IconButton aria-label="Baixar Relatório de Certificados" onClick={(e) => downloadRelatorio(e)}>
							<CloudDownloadIcon />
						</IconButton>
					</Box>
					<SelectField id="funcionarios" text="Funcionários" value={funcionario} setoption={setFuncionario} options={funcionarios} />
					<Box
						sx={{
							display: 'inline-flex',
							alignItems: 'center',
							marginY: 2,
						}}
					>
						<Input id='certificado' text='Certificado(SIGLA)' maxLength={'30'} />
						<Divider orientation="vertical" variant="middle" flexItem sx={{ marginX: 2 }} />
						<Input mask={DateMask} id='validade' text='Validade' formatChars={formatChars} />
					</Box>
				</CardContent>
				{items.length > 0 &&
					<TableContainer component={Paper} sx={{ marginY: 5 }}>
						<Table sx={{ minWidth: 650 }} aria-label="Lista de Certificados">
							<TableHead>
								<TableRow>
									<TableCell>Certificado</TableCell>
									<TableCell align="right">Validade</TableCell>
									<TableCell align="right"></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{items.map((row, index) => (
									<TableRow
										key={index}
									>
										<TableCell component="th" scope="row">{row.nome}</TableCell>
										<TableCell align="right">{row.validade}</TableCell>
										<TableCell align="right">{row.operations}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				}
				<CardActions sx={{ float: 'right', alignItems: 'flex-end' }}>
					<Button type="submit" variant="contained" id="addItem" title='Adicionar Item'>
						Adicionar Item
					</Button>
					<Button id="btnSave" onClick={(e) => addCertificates(e)} title='Salvar'>Salvar</Button>
				</CardActions>
			</Box>
		</Card>
	);
}
