import Swal from 'sweetalert2';

export const success = (title, text) => {
  Swal.fire({
    title: title,
    text: text,
    icon: 'success',
    confirmButtonText: 'Fechar'
  })
}

export const successWithReload = (title, text) => {
  Swal.fire({
    title: title,
    text: text,
    icon: 'success',
    confirmButtonText: 'Fechar',
  }).then((result) => {
    if (result.isConfirmed) {
      window.location.reload();
    }
  })
}

export const error = (title, text) => {
  Swal.fire({
    title: title,
    text: text,
    icon: 'error',
    confirmButtonText: 'Fechar'
  })
}