import { useEffect } from 'react';
import '../../styles/table.css';
import '../../styles/modal.css';
import { Input } from '../../components/Input';
import { InputSearch } from '../../components/InputSearch';
import { TableFuncHelper } from './helper';
import { MoedaInput } from '../../components/MoedaInput';
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { SelectField } from '../../components/SelectField';
import { MyInfo } from '../MyInfo';
import { RadioButton } from '../../components/RadioButton';

export const TableFunc = () => {
  const { searchSupervisoresAndAdministradores, searchEmpresas, handleCargo, refresh, loadings, updateCargo, createChaveJ, updateFinance, showFinance, setShowFinance, showChooseDownload, setShowChooseDownload, showChangeCargo, setShowChangeCargo, show, setShow, showCreateChaveJ, setShowCreateChaveJ, cargoChanged, funcionarioChangeCargo, funcionarioCreateChaveJ, cookie, empresas, supervisores, empresa, empresaChaveJ, supervisor, atuacao, chaveJ, funcionario, rows, textInfo, filter, custo, salario, ajuda, adiantamento, rendimento, internet, aluguel, lockFinance, chaveJFinance, setEmpresa, setCargoChanged, setSupervisor, setAtuacao, setChaveJ, setEmpresaChaveJ, setFilter, setCusto, setSalario, setAjuda, setAdiantamento, setRendimento, setInternet, setAluguel, setLockFinance, columns, showBackDrop, setShowBackDrop, id, cargo, listCargos, listCusto } = TableFuncHelper();

  useEffect(() => {
    document.getElementById('refresh').click();
    searchSupervisoresAndAdministradores();
    searchEmpresas();
  }, [searchSupervisoresAndAdministradores, searchEmpresas]);

  return (
    <div className="height-100">
      <Stack direction="row" sx={{ justifyContent: "space-between" }}>
        <RadioButton id='radio-list-func' text='' value={cargo} onSelect={handleCargo} items={listCargos} />
        {/* <Stack direction="row" sx={{ alignItems: 'flex-start' }}>
          <SelectField id="empresa" text="Empresa" value={empresa} setoption={setEmpresa} options={empresas} />
          <InputSearch filter={filter} setFilter={setFilter} />
        </Stack> */}
        <Stack direction="row">
          {cookie.tipo === 'administrador' &&
            <IconButton type="button" title="Baixar planilha" onClick={(e) => setShowChooseDownload(true)} sx={{ m: '8px' }}>
              <CloudDownloadIcon />
            </IconButton>
          }
          <Divider orientation="vertical" variant="top" flexItem sx={{ marginY: 2 }} />
          <IconButton id='refresh' type="button" title="Atualizar" onClick={(e) => refresh()} sx={{ m: '8px' }}>
            <RefreshIcon />
          </IconButton>
        </Stack>
      </Stack>
      <Box sx={{ width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          showCellVerticalBorder
          showColumnVerticalBorder
          // disableRowSelectionOnClick
          // unstable_rowSpanning
          disableColumnFilter
          pageSizeOptions={[25, 50, 100, 200, 500]}
          ignoreDiacritics
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
            sorting: {
              sortModel: [{ field: 'nome', sort: 'asc' }],
            },
            filter: {
              filterModel: {
                items: [],
                quickFilterExcludeHiddenColumns: true,
              }
            }
          }}
          sx={{
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'transparent',
            },
            'div': {
              borderColor: '#fff !important'
            }
          }}
        />
      </Box>
      <Dialog
        id="downloads"
        open={showChooseDownload}
        onClose={() => setShowChooseDownload(false)}
      >
        <DialogTitle>Escolha a planilha</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%' }}
        >
          <Button id="downloadActive" onClick={(e) => loadings(1)}>Ativos</Button>
          <Button id="downloadInactive" onClick={(e) => loadings(0)}>Inativos</Button>
          <Button id="downloadCompleted" onClick={(e) => loadings(3)}>Completo</Button>
        </DialogContent>
        <DialogActions sx={{ pb: 3, px: 3 }}>
          <Button onClick={() => setShowChooseDownload(false)}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      {
        funcionarioChangeCargo &&
        <Dialog
          id="form-change-cargo"
          open={showChangeCargo}
          onClose={() => setShowChangeCargo(false)}
          PaperProps={{
            component: 'form',
            onSubmit: (e) => {
              updateCargo(e);
            },
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>Mudar cargo de {funcionarioChangeCargo.nome}</DialogTitle>
          </Box>
          <DialogContent
            sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
          >
            <RadioButton id='radio-list-cargos' text='Cargo:' value={cargoChanged} onSelect={setCargoChanged} items={listCargos} />
            {cargoChanged === 'supervisor' &&
              <Input id="supervisorName" text="Supervisor" value={supervisor} onChange={(e) => setSupervisor(e.target.value)} />
            }
            {cargoChanged === 'agente' && supervisores.length > 0 &&
              <SelectField id="supervisores" text="Supervisor" value={supervisor} setoption={setSupervisor} options={supervisores} />
            }
            {(cargoChanged === 'agente' || cargoChanged === 'supervisor') &&
              <Input id="atuacao" text="Agência de atuação" value={atuacao} onChange={(e) => setAtuacao(e.target.value)} />
            }
          </DialogContent>
          <DialogActions sx={{ pb: 3, px: 3 }}>
            <Button onClick={() => setShowChangeCargo(false)}>Cancelar</Button>
            <Button variant="contained" type="submit">
              Continuar
            </Button>
          </DialogActions>
        </Dialog>
      }
      {
        show &&
        <MyInfo showInfo={show} setShowInfo={setShow} id={id} cargo={cargo} showDeleteCert={true} />
      }
      {
        funcionarioCreateChaveJ &&
        <Dialog
          id="form-create-chavej"
          open={showCreateChaveJ}
          onClose={() => setShowCreateChaveJ(false)}
          PaperProps={{
            component: 'form',
            onSubmit: (e) => {
              createChaveJ(e);
            },
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>Criar ChaveJ para {funcionarioCreateChaveJ.nome || ''}</DialogTitle>
          </Box>
          <DialogContent
            sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
          >
            <Input id="chavej" text="ChaveJ" maxLength='8' value={chaveJ} onChange={(e) => setChaveJ(e.target.value)} />
            <SelectField id="empresa" text="Empresa" value={empresaChaveJ} setoption={setEmpresaChaveJ} options={empresas} />
            <RadioButton id='radio-custo' text='Custo' value={custo} onSelect={setCusto} items={listCusto} />
            <MoedaInput id="salario" text="Salário" value={salario} onValueChange={setSalario} required={false} />
            <MoedaInput id="ajuda" text="Ajuda de custo" value={ajuda} onValueChange={setAjuda} required={false} />
            <MoedaInput id="rendimento" text="Rendimento fixo" value={rendimento} onValueChange={setRendimento} required={false} />
            <MoedaInput id="adiantamento" text="Adiantamento" value={adiantamento} onValueChange={setAdiantamento} required={false} />
            <MoedaInput id="internet" text="Internet" value={internet} onValueChange={setInternet} required={false} />
            <MoedaInput id="aluguel" text="Aluguel" value={aluguel} onValueChange={setAluguel} required={false} />
          </DialogContent>
          <DialogActions sx={{ pb: 3, px: 3 }}>
            <Button onClick={() => setShowCreateChaveJ(false)} title='Cancelar'>Cancelar</Button>
            <Button variant="contained" type="submit" title='Continuar'>
              Continuar
            </Button>
          </DialogActions>
        </Dialog>
      }
      {
        showFinance &&
        <Dialog
          id="form-dados-financeiros"
          open={showFinance}
          onClose={() => setShowFinance(false)}
          PaperProps={{
            component: 'form',
            onSubmit: (e) => {
              updateFinance(e);
            },
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>Modificar Dados Financeiros ({chaveJFinance.nome})</DialogTitle>
            <IconButton title={lockFinance ? 'Desbloquear edição' : 'Bloquear edição'} sx={{ margin: 1 }} onClick={(e) => setLockFinance(!lockFinance)}>
              {lockFinance ? <LockIcon /> : <LockOpenIcon />}
            </IconButton>
          </Box>
          <DialogContent
            sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
          >
            <RadioButton id='radio-custo' text='Custo' value={custo} onSelect={setCusto} items={listCusto} disabled={lockFinance} />
            <MoedaInput id="salario" text="Salário" value={salario} onValueChange={setSalario} required={false} disabled={lockFinance} />
            <MoedaInput id="ajuda" text="Ajuda de custo" value={ajuda} onValueChange={setAjuda} required={false} disabled={lockFinance} />
            <MoedaInput id="rendimento" text="Rendimento fixo" value={rendimento} onValueChange={setRendimento} required={false} disabled={lockFinance} />
            <MoedaInput id="adiantamento" text="Adiantamento" value={adiantamento} onValueChange={setAdiantamento} required={false} disabled={lockFinance} />
            <MoedaInput id="internet" text="Internet" value={internet} onValueChange={setInternet} required={false} disabled={lockFinance} />
            <MoedaInput id="aluguel" text="Aluguel" value={aluguel} onValueChange={setAluguel} required={false} disabled={lockFinance} />
          </DialogContent>
          <DialogActions sx={{ pb: 3, px: 3 }}>
            <Button onClick={() => setShowFinance(false)}>Cancelar</Button>
            <Button variant="contained" type="submit">
              Continuar
            </Button>
          </DialogActions>
        </Dialog>
      }
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={showBackDrop}
        onClick={() => setShowBackDrop(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div >
  )
}
