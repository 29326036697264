import prop from 'prop-types';
import { NavBar } from '../NavBar';
import { SideBar } from '../SideBar';
import { MyInfo } from '../../templates/MyInfo';

import '../../styles/sidebar.css';
import { Wave } from '../Wave';

export const Default = ({ toggle, headerpd, showSideBar, showInfo, setShowInfo, onClickShowInfo, onClickSignOut, nav, children }) => (
    <>
        <NavBar header={headerpd} setShow={onClickShowInfo} onClickSignOut={onClickSignOut} />
        <SideBar sidebar={nav} toggle={toggle} onClick={showSideBar} />
        {children}
        <MyInfo showInfo={showInfo} setShowInfo={setShowInfo} />
        <Wave />
    </>
);

Default.propTypes = {
    toggle: prop.object.isRequired,
    headerpd: prop.object.isRequired,
    showSideBar: prop.func.isRequired,
    showInfo: prop.bool.isRequired,
    setShowInfo: prop.func.isRequired,
    onClickShowInfo: prop.func.isRequired,
    onClickSignOut: prop.func.isRequired,
    nav: prop.object.isRequired,
    children: prop.node.isRequired
}
