import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { CheckCredentialsHelper } from './helper';
import { Input } from '../../components/Input';
import { CPFMask } from '../../util/mask';

export const CheckCredentials = ({ open, handleClose }) => {
  const { checkCredentials, emailError, emailErrorMessage, cpfError, cpfErrorMessage } = CheckCredentialsHelper();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          
          checkCredentials(event, handleClose);
        },
      }}
    >
      <DialogTitle>Redefinir Senha</DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
      >
        <DialogContentText>
          Digite seu endereço de email e cpf para verificar suas credenciais.
          Em breve enviaremos um link via email para prosseguir com a redefinição de senha.
        </DialogContentText>
        <Input id='email' text='Email' placeholder='Email' autofocus={true} error={emailError} errorMessage={emailErrorMessage} />
        <Input mask={CPFMask} id='cpf' text='CPF' placeholder='CPF' error={cpfError} errorMessage={cpfErrorMessage} />
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 3 }}>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button variant="contained" type="submit">
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CheckCredentials.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

