import { useState, useCallback } from 'react';
import axios from 'axios';
import { error, successWithReload } from '../../util/alert';
import { useCookies } from 'react-cookie';
import { checkAuthenticity } from '../../util/auth';
import { BACKEND_URL } from '../../util/config';
import { convertDate } from '../../util/filter';
import Swal from 'sweetalert2';
import { saveAs } from "file-saver";
import { MdRemove } from 'react-icons/md';

export const AddCertificateHelper = () => {
  const [cookie, setCookie] = useCookies(['auth']);

  const [funcionario, setFuncionario] = useState('');

  const [count, setCount] = useState(0);

  const [funcionarios, setFuncionarios] = useState([]);

  const [items, setItems] = useState([]);

  /*Função para procurar funcionários dinamicamente. */
  const searchFuncionarios = useCallback(async () => {
    await axios
      .get(BACKEND_URL + "funcionarios",
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
      .then(response => {
        let funcionariosData = response.data.funcionarios;
        let funcionarios = [];

        funcionariosData.forEach(function (funcionario) {
          funcionarios.push({
            value: funcionario.id,
            label: funcionario.nome,
          })
        })
        setFuncionarios(funcionarios);
      })
      .catch(erro => {
        console.log(erro);
        checkAuthenticity(erro.response.status);
      })
  }, [cookie])

  /*Função auxiliar para verificar preenchimento dos campos. */
  const isEmpty = () => {
    if (funcionario && items.length > 0)
      return false;
    else
      return true;
  }

  /*Função para montar json com certificados a serem enviados. */
  function addCertificates(e) {
    e.preventDefault();

    if (isEmpty()) {
      error("Campos vazios", "Revise e preencha todos os campos necessários.");
      return null;
    }
    else {
      let itemsFormat = items;
      for (let i = 0; i < itemsFormat.length; i++) {
        delete itemsFormat[i].id;
        delete itemsFormat[i].operations;
      }

      let jsonData = { certificados: itemsFormat }

      console.log(jsonData);

      Swal.fire({
        title: 'Salvando certificado',
        allowEscapeKey: false,
        allowOutsideClick: false,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          sendCertificates(jsonData)
            .then(response => {
              console.log(response);
              successWithReload('Sucesso', 'Certificado salvo!');
            })
            .catch(erro => {
              error('Erro', 'Não foi possível carregar as informações.')
              console.log(erro);
              checkAuthenticity(erro.response.status);
            })
        }
      })
    }
  }

  /*Função para enviar informações de certificados do funcionário. */
  const sendCertificates = (certificates) => {
    return axios
      .post(BACKEND_URL + "funcionario/certificado/" + funcionario, certificates,
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
  }

  /*Função auxiliar para remover certificados a serem enviados. */
  const removeItemOfTable = (e, index) => {
    e.preventDefault();

    setItems(oldValues => {
      return oldValues.filter((item, i) => item.id !== index)
    })
  }

  /*Função auxiliar para adicionar certificados a serem enviados. */
  const addItemToTable = (e) => {
    e.preventDefault();

    const data = new FormData(e.currentTarget);

    let certificado = data.get('certificado');
    let validade = data.get('validade');

    let item = {
      id: count,
      nome: certificado.toUpperCase(),
      validade: convertDate(validade),
      operations: <button className="text-white btn-icon btn-delete" title="Remover item" onClick={(e) => removeItemOfTable(e, count)}><MdRemove /></button>
    }

    setItems(items => [...items, item]);
    setCount(newCount => count + 1);

    data.set('certificado', '');
    data.set('validade', '');
  }

  /*Função auxiliar de requisição dos certificados existentes. */
  const download = () => {
    return axios
      .get(BACKEND_URL + `certificados/`,
        {
          responseType: "blob",
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
  }

  /*Função para baixar relatório de certificados. */
  const downloadRelatorio = (e) => {
    e.preventDefault();

    Swal.fire({
      title: 'Baixando',
      allowEscapeKey: false,
      allowOutsideClick: false,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        download()
          .then(response => {
            saveAs(response.data, "Relatório Certificações(" + new Date().toLocaleDateString().replace("_", "/") + ").xlsx");
            Swal.close();
          })
          .catch(erro => {
            error('Erro', 'Não foi possível baixar o arquivo.')
            console.log(erro);
            checkAuthenticity(erro.response.status);
          })
      }
    })
  }

  return { searchFuncionarios, downloadRelatorio, addItemToTable, addCertificates, funcionarios, funcionario, items, setFuncionario }
}
