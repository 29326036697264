import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import prop from 'prop-types';

export const RadioButton = ({ id, text, value, onSelect, items, disabled }) => (
    <FormControl>
        <FormLabel id={id} sx={{ textAlign: 'start' }}>{text}</FormLabel>
        <RadioGroup
            row
            aria-labelledby={text}
            value={value}
            name={id}
            onChange={(e) => onSelect(e.target.value)}
        >
            {items.map((item, index) =>
                <FormControlLabel key={index} value={item.value} control={<Radio />} label={item.label} />
            )}
        </RadioGroup>
    </FormControl>
);

RadioButton.defaultProps = {
    value: null,
    disabled: false
}

RadioButton.propTypes = {
    id: prop.string.isRequired,
    text: prop.string.isRequired,
    value: prop.string,
    onSelect: prop.func.isRequired,
    items: prop.array.isRequired,
    disabled: prop.bool
}
