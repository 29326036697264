import { useState, useCallback } from 'react';
import { saveAs } from "file-saver";
import axios from 'axios';
import { error, successWithReload } from '../../util/alert';
import { useCookies } from 'react-cookie';
import { checkAuthenticity } from '../../util/auth';
import { BACKEND_URL } from '../../util/config';
import { filterCharacters } from '../../util/filter';
import { formatterCEP, formatterCNPJ, formatterCPF, formatterDate } from '../../util/formatter';
import Swal from 'sweetalert2';

export const AddDocumentHelper = () => {
  const [cookie, setCookie] = useCookies(['auth']);

  const [funcionario, setFuncionario] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [estadoCivil, setEstadoCivil] = useState('');
  const [profissao, setProfissao] = useState('');

  const [funcionarios, setFuncionarios] = useState([]);

  const [empresas, setEmpresas] = useState([]);

  const [infoFuncionario, setInfoFuncionario] = useState('');
  const [infoEmpresa, setInfoEmpresa] = useState('');
  const [estadosCivis, setEstadosCivis] = useState([
    { value: 'solteiro(a)', label: 'Solteiro(a)' },
    { value: 'casado(a)', label: 'Casado(a)' },
    { value: 'separado(a)', label: 'Separado(a)' },
    { value: 'divorciado(a)', label: 'Divorciado(a)' },
    { value: 'viúvo(a)', label: 'Viúvo(a)' }
  ]);

  /*Função para procurar funcionários dinamicamente. */
  const searchFuncionarios = useCallback(async () => {
    await axios
      .get(BACKEND_URL + "funcionarios",
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
      .then(response => {
        let funcionariosData = response.data.funcionarios;
        let funcionarios = [];

        funcionariosData.forEach(function (funcionario) {
          funcionarios.push({
            value: funcionario.cargo_id + ',' + funcionario.cargo,
            label: funcionario.nome,
          })
        })
        setFuncionarios(funcionarios);
      })
      .catch(erro => {
        console.log(erro);
        checkAuthenticity(erro.response.status);
      })
  }, [cookie])

  /*Função para procurar empresas dinamicamente. */
  const searchEmpresas = useCallback(async () => {
    await axios
      .get(BACKEND_URL + "empresas/",
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
      .then(response => {
        let empresasData = response.data.empresas;
        let empresas = [];

        empresasData.forEach(empresa => {
          empresas.push({
            value: empresa.cnpj,
            label: empresa.nome,
          })
        })
        setEmpresas(empresas);
      })
      .catch(erro => {
        console.log(erro);
        checkAuthenticity(erro.response.status);
      })
  }, [cookie])

  /*Função para procurar um funcionário dinamicamente. */
  const searchFuncionario = useCallback(async (id, currentCargo) => {
    await axios
      .get(BACKEND_URL + currentCargo + "/" + id,
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
      .then(response => {
        let jsonData = {
          funcionario: {

          }
        };

        jsonData.funcionario.nome = response.data.nome;
        jsonData.funcionario.cpf = formatterCPF(response.data.cpf);
        jsonData.funcionario.rg = response.data.rg;
        jsonData.funcionario.data_nasc = formatterDate(response.data.data_nasc);
        jsonData.funcionario.endereco = `${response.data.endereco.rua}, Bairro: ${response.data.endereco.bairro}, nº ${response.data.endereco.numero}, CEP: ${formatterCEP(response.data.endereco.cep)}, ${response.data.endereco.cidade} - ${response.data.endereco.uf}`;
        jsonData.funcionario.dados_bancarios = response.data.dados_bancarios;
        jsonData.funcionario.pix = response.data.pix;
        jsonData.funcionario.email = response.data.email;
        jsonData.funcionario.telefone = response.data.telefone;

        setInfoFuncionario(jsonData);
      })
      .catch(erro => {
        error('Erro', 'Não foi possível carregar as informações.')
        console.log(erro);
        checkAuthenticity(erro.response.status);
      })
  }, [cookie])

  /*Função para procurar um funcionário dinamicamente. */
  const searchEmpresa = useCallback(async (cnpj) => {
    await axios
      .get(BACKEND_URL + "empresa/" + cnpj,
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
      .then(response => {
        let jsonData = {
          empresa: {

          }
        };

        jsonData.empresa.contratante = response.data.nome;
        jsonData.empresa.cnpj = formatterCNPJ(response.data.cnpj);
        jsonData.empresa.endereco = response.data.sede;
        jsonData.empresa.cep = response.data.cep_sede;
        jsonData.empresa.representante = response.data.representante;
        jsonData.empresa.cpf_representante = response.data.cpf_representante;

        setInfoEmpresa(jsonData);
      })
      .catch(erro => {
        error('Erro', 'Não foi possível carregar as informações.')
        console.log(erro);
        checkAuthenticity(erro.response.status);
      })
  }, [cookie])

  /*Função para baixar contrato do funcionário. */
  function downloadContrato(event) {
    event.preventDefault();

    let jsonData = Object.assign({}, infoFuncionario, infoEmpresa)
    jsonData.funcionario.sexo = 'Fem';
    jsonData.funcionario.estado_civil = estadoCivil;
    jsonData.funcionario.profissao = profissao;

    console.log(jsonData)

    Swal.fire({
      title: 'Gerando contrato',
      allowEscapeKey: false,
      allowOutsideClick: false,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        getContrato(jsonData)
          .then(response => {
            console.log(response);
            saveAs(response.data, "Contrato(" + infoFuncionario.funcionario.nome + ").docx");
            successWithReload('Sucesso', 'O contrato está sendo baixado!');
          })
          .catch(erro => {
            error('Erro', 'Não foi possível carregar as informações.')
            console.log(erro);
            checkAuthenticity(erro.response.status);
          })
      }
    })
  }

  const getContrato = (jsonData) => {
    return axios
      .post(BACKEND_URL + "funcionario/contrato/1", jsonData,
        {
          responseType: "blob",
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
  }

  /*Função para enviar contrato do funcionário. */
  function sendContrato(event) {
    event.preventDefault();

    let jsonData = Object.assign({}, infoFuncionario, infoEmpresa)
    jsonData.funcionario.sexo = 'Fem';
    jsonData.funcionario.estado_civil = estadoCivil;
    jsonData.funcionario.profissao = profissao;

    console.log(jsonData)

    Swal.fire({
      title: 'Gerando contrato',
      allowEscapeKey: false,
      allowOutsideClick: false,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        sendContratoForEmail(jsonData)
          .then(response => {
            console.log(response);
            successWithReload('Sucesso', 'O contrato foi enviado para o email do parceiro!');
          })
          .catch(erro => {
            error('Erro', 'Não foi possível carregar as informações.')
            console.log(erro);
            checkAuthenticity(erro.response.status);
          })
      }
    })
  }

  const sendContratoForEmail = (jsonData) => {
    return axios
      .post(BACKEND_URL + "funcionario/send/1", jsonData,
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
  }

  const handleFuncionario = (funcionario) => {
    setFuncionario(funcionario);
    let func = funcionario.split(",");
    searchFuncionario(parseInt(func[0]), func[1]);
  }

  const handleEmpresa = (empresa) => {
    setEmpresa(empresa);
    searchEmpresa(filterCharacters(empresa));
  }

  return { searchFuncionarios, searchEmpresas, handleEmpresa, handleFuncionario, downloadContrato, sendContrato, empresas, funcionarios, estadosCivis, empresa, funcionario, estadoCivil, profissao, setEstadoCivil, setProfissao }
}
