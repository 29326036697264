import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import { gray } from '../themePrimitives';

export const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

export const SignInContainer = styled(Stack)(({ theme }) => ({
  padding: 20,
  marginTop: '10vh',
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    zIndex: -1,
    inset: 0,
    backgroundImage: 'transparent',
    backgroundRepeat: 'no-repeat',
  },
}));

export const IconExpandSidebar = styled(IconButton)(({ theme }) => ({
  ...theme.applyStyles('light', {
    color: gray[700],
    '&:hover': {
      color: gray[300],
    },
  })
}))

export const textCardStyle = [
  (theme) => ({
    color: 'var(--color-body) !important',
    ...theme.applyStyles('dark', {
      color: 'var(--color-primary) !important',
    }),
  }),
]

export const linkHoverCardStyle = [
  (theme) => ({
    '&:hover': {
      color: 'var(--color-body) !important',
      ...theme.applyStyles('dark', {
        color: 'var(--color-primary) !important',
      })
    },
  }),
]
