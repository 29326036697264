import { Grid2, Card, CardContent, Typography } from '@mui/material';
import prop from 'prop-types';

export const CountCard = ({ quantity, text }) => (
  <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
          {quantity}
        </Typography>
        <Typography variant="h5" component="div">
          {text}
        </Typography>
      </CardContent>
    </Card>
  </Grid2>
);

CountCard.propTypes = {
  quantity: prop.number.isRequired,
  text: prop.string.isRequired,
}
