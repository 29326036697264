import { useState, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { BACKEND_URL } from '../../util/config';
import { error, successWithReload } from '../../util/alert';
import { validate } from '../../util/validate';
import { checkAuthenticity } from '../../util/auth';
import { convertDate, dateToString, filterCharacters, filterMoeda } from '../../util/filter';
import { capitalize } from '../../util/typography';
import { formatterConta, formatterMoeda } from '../../util/formatter';
import { checkContaBanco } from '../../util/checks';

/* Página do formulário para cadastro de funcionários, 
  contendo todos os campos necessários.
*/
export const FormAddFuncHelper = () => {
  const [cookie, setCookie] = useCookies(['auth']);
  const [text, setText] = useState('');
  const [textConfirm, setTextConfirm] = useState('');
  const [titleConfirm, setTitleConfirm] = useState('');
  const [id, setId] = useState('');

  //Dados Pessoais
  const [nome, setNome] = useState('');
  const [chavej, setChavej] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [rg, setRg] = useState('');
  const [expedidor, setExpedidor] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [dataInicio, setDataInicio] = useState('');
  const [sexo, setSexo] = useState('');
  const [estadoCivil, setEstadoCivil] = useState('');
  const [profissao, setProfissao] = useState('');
  const [camisa, setCamisa] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [cargo, setCargo] = useState('');
  const [supervisor, setSupervisor] = useState('');
  const [atuacao, setAtuacao] = useState('');

  //Endereço
  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [bairro, setBairro] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [localidade, setLocalidade] = useState('');
  const [uf, setUf] = useState('');

  //Dados Financeiros
  const [custo, setCusto] = useState('');
  const [salario, setSalario] = useState('');
  const [ajuda, setAjuda] = useState('');
  const [adiantamento, setAdiantamento] = useState('');
  const [rendimento, setRendimento] = useState('');
  const [internet, setInternet] = useState('');
  const [aluguel, setAluguel] = useState('');

  //Comissões
  const [comissao, setComissao] = useState('');
  const [credito, setCredito] = useState('');
  const [consorcio, setConsorcio] = useState('');
  const [contaCorrente, setContaCorrente] = useState('');
  const [ourocap, setOurocap] = useState('');
  const [bbDental, setBbDental] = useState('');
  const [seguros, setSeguros] = useState('');
  const [comissaoSupervisor, setComissaoSupervisor] = useState('');
  const [outros, setOutros] = useState('');
  const [ajuste, setAjuste] = useState('');

  //Dados Bancários
  const [tipoConta, setTipoConta] = useState('');
  const [banco, setBanco] = useState('');
  const [agencia, setAgencia] = useState('');
  const [conta, setConta] = useState('');
  const [variacao, setVariacao] = useState('');
  const [tipoPix, setTipoPix] = useState('');
  const [pix, setPix] = useState('');
  const [checkConta, setCheckConta] = useState(false);
  const [checkPix, setCheckPix] = useState(false);

  //Contato
  const [telefone, setTelefone] = useState('');

  const [comissoes, setComissoes] = useState([
    { value: 'credito', label: 'Comissão de Crédito' },
    { value: 'consorcio', label: 'Comissão de Consórcio' },
    { value: 'contaCorrente', label: 'Comissão de Conta Corrente' },
    { value: 'ourocap', label: 'Comissão de Ourocap' },
    { value: 'bbDental', label: 'Comissão de BB Dental' },
    { value: 'seguros', label: 'Comissão de Seguros' },
    { value: 'comissaoSupervisor', label: 'Comissão de Supervisor' },
    { value: 'outros', label: 'Comissão Outros' },
    { value: 'ajuste', label: 'Ajuste' }
  ]);

  const [funcionario, setFuncionario] = useState('');
  const [funcionarios, setFuncionarios] = useState([]);
  const [bancos, setBancos] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [supervisores, setSupervisores] = useState([]);
  const [estadosCivis, setEstadosCivis] = useState([
    { value: 'solteira', label: 'Solteiro(a)' },
    { value: 'casada', label: 'Casado(a)' },
    { value: 'separada', label: 'Separado(a)' },
    { value: 'divorciada', label: 'Divorciado(a)' },
    { value: 'viúva', label: 'Viúvo(a)' }
  ]);
  const [sexos, setSexos] = useState([
    { value: 'Masc', label: 'Masculino' },
    { value: 'Fem', label: 'Feminino' }
  ]);
  const [camisas, setcamisas] = useState([
    { value: 'P', label: 'P' },
    { value: 'M', label: 'M' },
    { value: 'G', label: 'G' },
    { value: 'GG', label: 'GG' }
  ]);
  const [cargos, setCargos] = useState([
    { value: 'agente', label: 'Agente' },
    { value: 'supervisor', label: 'Supervisor' },
    { value: 'administrador', label: 'Administrador' }
  ]);
  const [tiposConta, setTiposConta] = useState([
    { value: 'corrente', label: 'Corrente' },
    { value: 'poupanca', label: 'Poupança' },
  ]);
  const [tiposPix, setTiposPix] = useState([
    { value: 'email', label: 'Email' },
    { value: 'telefone', label: 'Telefone' },
    { value: 'cpf', label: 'CPF' }
  ]);
  const [custos, setCustos] = useState([
    { value: 'true', label: 'Sim' },
    { value: 'false', label: 'Não' }
  ]);

  const [showButtons, setShowButtons] = useState(true);
  const [showSelectFuncionarios, setShowSelectFuncionarios] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showCredito, setShowCredito] = useState(false);
  const [showConsorcio, setShowConsorcio] = useState(false);
  const [showContaCorrente, setShowContaCorrente] = useState(false);
  const [showOurocap, setShowOurocap] = useState(false);
  const [showBbDental, setShowBbDental] = useState(false);
  const [showSeguros, setShowSeguros] = useState(false);
  const [showComissaoSupervisor, setShowComissaoSupervisor] = useState(false);
  const [showOutros, setShowOutros] = useState(false);
  const [showAjuste, setShowAjuste] = useState(false);
  const [showCargo, setShowCargo] = useState(false);
  const [showChaveJ, setShowChaveJ] = useState(false);
  const [showBackDrop, setShowBackDrop] = useState(false);

  /*Função para limpar todos os campos. */
  const clearInputs = () => {
    setSexo("");
    setEstadoCivil("");
    setProfissao("");

    setComplemento("");

    setTipoConta("");
    setVariacao("");
    setBanco("");
    setAgencia("");
    setConta("");
    setTipoPix("");
    setPix("");

    setCusto("")
    setSalario("");
    setAjuda("");
    setAdiantamento("");
    setRendimento("");
    setInternet("");
    setAluguel("");
    setTelefone("");
    setCredito("");
    setShowCredito(false);
    setConsorcio("");
    setShowConsorcio(false);
    setContaCorrente("");
    setShowContaCorrente(false);
    setOurocap("");
    setShowOurocap(false);
    setBbDental("");
    setShowBbDental(false);
    setSeguros("");
    setShowSeguros(false);
    setComissaoSupervisor("");
    setShowComissaoSupervisor(false);
    setOutros("");
    setShowOutros(false);
    setAjuste("");
    setShowAjuste(false);
  }

  /*Função para procurar bancos dinamicamente. */
  const searchBancos = useCallback(async () => {
    await axios
      .get(BACKEND_URL + "banco/",
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
      .then(response => {
        let bancosData = response.data.bancos;
        let bancos = [];

        bancosData.forEach(banco => {
          bancos.push({
            value: banco.id,
            label: banco.nome,
          })
        })
        setBancos(bancos);
      })
      .catch(erro => {
        console.log(erro);
        checkAuthenticity(erro.response.status);
      })
  }, [cookie, setBancos])

  /*Função para procurar empresas dinamicamente. */
  const searchEmpresas = useCallback(async () => {
    await axios
      .get(BACKEND_URL + "empresas/",
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
      .then(response => {
        let empresasData = response.data.empresas;
        let empresas = [];

        empresasData.forEach(empresa => {
          empresas.push({
            value: empresa.cnpj,
            label: empresa.nome,
          })
        })
        setEmpresas(empresas);
      })
      .catch(erro => {
        console.log(erro);
        checkAuthenticity(erro.response.status);
      })
  }, [cookie])

  /*Função para procurar supervisores dinamicamente. */
  const searchSupervisoresAndAdministradores = useCallback(async () => {
    await axios
      .get(BACKEND_URL + "supervisores_validos/",
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
      .then(response => {
        let supervisoresData = response.data.supervisores;
        let supervisores = [];

        supervisoresData.forEach(function (supervisor) {
          supervisores.push({
            value: supervisor.cpf,
            label: supervisor.nome,
            is_admin: supervisor.is_admin
          })
        })
        setSupervisores(supervisores);
      })
      .catch(erro => {
        console.log(erro);
        checkAuthenticity(erro.response.status);
      })
  }, [cookie])

  /*Função para procurar funcionários dinamicamente. */
  const searchFuncionarios = useCallback(async () => {
    await axios
      .get(BACKEND_URL + "funcionarios",
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
      .then(response => {
        let funcionariosData = response.data.funcionarios;
        let funcionarios = [];

        funcionariosData.forEach(function (funcionario) {
          funcionarios.push({
            value: funcionario.cargo_id + ',' + funcionario.cargo,
            label: funcionario.nome,
          })
        })
        setFuncionarios(funcionarios);
      })
      .catch(erro => {
        console.log(erro);
        checkAuthenticity(erro.response.status);
      })
  }, [cookie])

  /*Função para procurar um funcionário dinamicamente. */
  const searchFuncionario = useCallback(async (id, currentCargo) => {
    clearInputs();
    axios
      .get(BACKEND_URL + currentCargo + "/" + id,
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
      .then(response => {
        console.log(response.data)

        let { nome, cpf, rg, orgao_expedidor, email, data_nasc, data_inicio, sexo, estado_civil, profissao, tam_camisa, ag_atuacao, super_supervisor } = response.data;
        data_inicio = dateToString(data_inicio);
        data_nasc = dateToString(data_nasc);
        let supervisor = super_supervisor ? super_supervisor : response.data.supervisor_cpf;
        let { cep, rua, bairro, numero, complemento, cidade, uf } = response.data.endereco;
        let { credito, consorcio, conta_corrente, ourocap, bb_dental, seguros, com_supervisor, outros, ajuste } = response.data.comissao;
        let tipo = '';
        let chave = '';
        let tipo_conta = '';
        let agencia = '';
        let conta = '';
        let banco = '';
        let variacao_poupanca = '';
        if (response.data.dados_bancarios) {
          tipo_conta = response.data.dados_bancarios.tipo_conta;
          agencia = response.data.dados_bancarios.agencia;
          conta = response.data.dados_bancarios.conta;
          variacao_poupanca = response.data.dados_bancarios.variacao_poupanca;
          banco = response.data.dados_bancarios.banco.id;
        }
        if (response.data.pix) {
          tipo = response.data.pix.tipo;
          chave = response.data.pix.chave;
        }

        let telefone = response.data.telefone;

        setNome(nome);
        setEmail(email);
        setCpf(cpf);
        setRg(rg);
        setExpedidor(orgao_expedidor);
        setDataNascimento(data_nasc);
        setDataInicio(data_inicio);
        if (sexo) {
          setSexo(sexo);
        }
        if (estado_civil)
          setEstadoCivil(estado_civil);
        if (profissao)
          setProfissao(profissao);
        setCamisa(tam_camisa);
        setCargo(currentCargo);
        setAtuacao(ag_atuacao);
        setSupervisor(supervisor);

        setCep(cep);
        setLogradouro(rua);
        setBairro(bairro);
        setNumero(numero);
        setComplemento(complemento);
        setLocalidade(cidade);
        setUf(uf);

        if (response.data.dados_bancarios && response.data.dados_bancarios != null) {
          setCheckConta(true);
          setTipoConta(tipo_conta);
          if (tipo_conta === 'poupanca')
            setVariacao(variacao_poupanca.toString());
          setBanco(banco);
          setAgencia(agencia);
          setConta(formatterConta(conta));
        }
        if (response.data.pix) {
          setCheckPix(true);
          setTipoPix(tipo);
          setPix(chave);
        }

        setTelefone(telefone);

        if (credito) {
          setCredito(formatterMoeda(credito))
          setShowCredito(true)
        }
        if (consorcio) {
          setConsorcio(formatterMoeda(consorcio))
          setShowConsorcio(true)
        }
        if (conta_corrente) {
          setContaCorrente(formatterMoeda(conta_corrente))
          setShowContaCorrente(true)
        }
        if (ourocap) {
          setOurocap(formatterMoeda(ourocap))
          setShowOurocap(true)
        }
        if (bb_dental) {
          setBbDental(formatterMoeda(bb_dental))
          setShowBbDental(true)
        }
        if (seguros) {
          setSeguros(formatterMoeda(seguros))
          setShowSeguros(true)
        }
        if (com_supervisor) {
          setComissaoSupervisor(formatterMoeda(com_supervisor))
          setShowComissaoSupervisor(true)
        }
        if (outros) {
          setOutros(formatterMoeda(outros))
          setShowOutros(true)
        }
        if (ajuste) {
          setAjuste(formatterMoeda(ajuste))
          setShowAjuste(true)
        }
        setShowBackDrop(false);
      })
      .catch(erro => {
        error('Erro', 'Não foi possível carregar as informações.')
        console.log(erro);
        checkAuthenticity(erro.response.status);
      })
  }, [cookie])

  /* Função para buscar e preencher o endereço dinamicamente a partir do CEP.
  */
  const searchAdressForCEP = useCallback(async (cepFormated) => {
    //verifica se o usuário terminou de digitar o cep
    if (cepFormated.length === 8) {
      document.getElementById('localidade').disabled = false;
      document.getElementById('uf').disabled = false;
      let input = document.getElementById('cep');
      //busca o endereço do cep digitado
      await axios
        .get(`https://viacep.com.br/ws/` + cepFormated + `/json/ `)
        .then(response => {
          if (!response.data.erro) {
            validate(input, true);
            setLogradouro(response.data.logradouro);
            validate(document.getElementById('logradouro'), true);
            setBairro(response.data.bairro);
            validate(document.getElementById('bairro'), true);
            setComplemento(response.data.complemento);
            setLocalidade(response.data.localidade);
            validate(document.getElementById('localidade'), true);
            document.getElementById('localidade').disabled = true;
            setUf(response.data.uf);
            validate(document.getElementById('uf'), true);
            document.getElementById('uf').disabled = true;
          }
          else {
            validate(input, false);
          }
        })
        .catch(erro => {
          console.log(erro);
        })
    }
  }, [])

  /* Função para checar se todos os campos necessários estão preenchidos.*/
  const isEmpty = () => {
    if (nome && (chavej || !showChaveJ) && email && cpf && rg && expedidor && dataNascimento && camisa && (empresa || !showChaveJ) && cep && logradouro && bairro && numero && localidade && uf && telefone) {
      if (checkConta && tipoConta === 'poupanca' && variacao && agencia && conta && banco)
        return false;
      else if (checkConta && tipoConta === 'corrente' && agencia && conta && banco)
        return false;
      else if (checkPix && tipoPix && pix)
        return false;
      else
        return true;
    }
    return true;
  }

  /* Função para checar se todos os campos preenchidos são válidos.*/
  const isValid = () => {
    if (document.querySelectorAll('[id$="helper-text"]').length > 0)
      return false;
    else
      return true;
  }

  /* Função para checar se foi escolhido o cargo do funcionário.*/
  const checkCargo = () => {
    if (cargo === 'agente' || cargo === 'supervisor' || cargo === 'administrador')
      return true;
    else
      return false;
  }

  /* Requisição para criar um funcionário, enviando 
    todos os dados do formulário preenchido para o servidor 
    cadastrar no banco de dados.
  */
  function createUpdateFunc(event) {
    event.preventDefault();

    setShowBackDrop(true);

    document.getElementById('btnSubmit').disabled = true;

    let jsonData = getData();
    if (jsonData) {
      axios
        .post(BACKEND_URL + cargo + "/" + id, jsonData,
          {
            headers: {
              Authorization: ["Bearer", cookie.access_token].join(" "),
            },
          })
        .then(response => {
          successWithReload(capitalize(cargo) + ' ' + titleConfirm, textConfirm + ' de ' + cargo + ' realizada com sucesso!');
        })
        .catch(erro => {
          if (erro.response.status === 409)
            error('Funcionário duplicado', 'Esta chaveJ já está vinculada à outro funcionário.');
          else
            error('Erro', 'Aconteceu um erro inesperado, tente novamente. Caso o problema persista, entre em contato com o suporte.');
          console.log(erro);
          checkAuthenticity(erro.response.status);
        }).finally(() => {
          setShowBackDrop(false);
          document.getElementById('btnSubmit').disabled = false;
        })
    }
    setShowBackDrop(false);
    document.getElementById('btnSubmit').disabled = false;
  }

  /* Função de sanitização de dados do formulário, preparando-os
    para o envio para o servidor em json formatado.
  */
  const getData = () => {
    //realiza as checagens necessárias
    if (isEmpty()) {
      error("Campos vazios", "Revise e preencha todos os campos necessários.");
      return null;
    }
    else if (!isValid()) {
      error("Campos inválidos", "Revise e preencha os campos corretamente.");
      return null;
    }
    else if (!checkCargo()) {
      error("Cargo não selecionado", "Necessário escolher um cargo.");
      return null;
    }
    else if ((cargo === 'agente' || cargo === 'supervisor') && !atuacao) {
      error("Campo vazio", "Digite a agência de atuação do funcionário.");
      return null;
    }
    else if (!checkConta && !checkPix) {
      error("Campo vazio", "Funcionário deve ter pelo menos um dado bancário.");
      return null;
    }
    else if (checkConta && !(tipoConta === 'poupanca' || tipoConta === 'corrente')) {
      error("Campo vazio", "Tipo da conta bancária não selecionado.");
      return null;
    }
    else {
      //sanitiza os dados
      let convertedDataNascimento = convertDate(dataNascimento);
      let convertedDataInicio = convertDate(dataInicio);

      var jsonData = {}
      jsonData = {
        funcionario: {
          nome: nome,
          cpf: filterCharacters(cpf),
          rg: filterCharacters(rg),
          orgao_expedidor: expedidor,
          email: email,
          data_nasc: convertedDataNascimento,
          data_inicio: convertedDataInicio,
          sexo: sexo,
          estado_civil: estadoCivil,
          profissao: profissao,
          tam_camisa: camisa,
          endereco: {
            cep: filterCharacters(cep),
            rua: logradouro,
            bairro: bairro,
            numero: numero,
            complemento: complemento,
            cidade: localidade,
            uf: uf
          },
          dados_financeiros: {
            salario: filterMoeda(salario),
            aj_custo: filterMoeda(ajuda),
            adiantamento: filterMoeda(adiantamento),
            rendimento: filterMoeda(rendimento),
            internet: filterMoeda(internet),
            aluguel: filterMoeda(aluguel),
          },
          comissao: {
            credito: filterMoeda(credito),
            consorcio: filterMoeda(consorcio),
            conta_corrente: filterMoeda(contaCorrente),
            ourocap: filterMoeda(ourocap),
            bb_dental: filterMoeda(bbDental),
            seguros: filterMoeda(seguros),
            comissao_supervisor: filterMoeda(comissaoSupervisor),
            outros: filterMoeda(outros),
            ajuste: filterMoeda(ajuste)
          },
          telefone: {
            ddd: filterCharacters(telefone).slice(0, 2),
            numero: filterCharacters(telefone).slice(2)
          }
        }
      }

      if (chavej && showChaveJ && empresa) {
        jsonData.funcionario.chavej = chavej;
        jsonData.funcionario.empresa = empresa;
      }

      if (custo) {
        if (custo === 'true')
          jsonData.funcionario.dados_financeiros.custo = true;
        else
          jsonData.funcionario.dados_financeiros.custo = false;
      }

      if (cargo === 'agente') {
        let supervisorSelected = supervisores.find((selected, index, array) => selected.value === supervisor);
        jsonData.supervisor = supervisorSelected.value;
        jsonData.is_admin = supervisorSelected.is_admin;
      } else if (cargo === 'supervisor') {
        jsonData.super_supervisor = supervisor;
      }

      //adiciona a agência caso não seja um administrador
      if (cargo === 'agente' || cargo === 'supervisor') {
        jsonData.ag_atuacao = atuacao;
      }

      //adiciona a variação caso seja um conta poupança
      if (checkConta && (tipoConta === 'poupanca' || tipoConta === 'corrente')) {
        jsonData.funcionario.dados_bancarios = {};
        if (tipoConta === 'poupanca')
          jsonData.funcionario.dados_bancarios.variacao_poupanca = variacao;
        jsonData.funcionario.dados_bancarios.tipo_conta = tipoConta;
        jsonData.funcionario.dados_bancarios.agencia = filterCharacters(agencia);
        jsonData.funcionario.dados_bancarios.conta = filterCharacters(conta);
        jsonData.funcionario.dados_bancarios.banco = banco;
      }
      if (checkPix) {
        jsonData.funcionario.pix = {};
        jsonData.funcionario.pix.tipo = tipoPix;
        jsonData.funcionario.pix.chave = pix;
      }

      return jsonData;
    }
  }

  const createInput = (input) => {
    setComissao(input);
    switch (input) {
      case 'credito':
        setShowCredito(true);
        break;

      case 'consorcio':
        setShowConsorcio(true);
        break;

      case 'contaCorrente':
        setShowContaCorrente(true);
        break;

      case 'ourocap':
        setShowOurocap(true);
        break;

      case 'bbDental':
        setShowBbDental(true);
        break;

      case 'seguros':
        setShowSeguros(true);
        break;

      case 'comissaoSupervisor':
        setShowComissaoSupervisor(true);
        break;

      case 'outros':
        setShowOutros(true);
        break;

      case 'ajuste':
        setShowAjuste(true);
        break;

      default:
        error('Erro', 'Não foi possível carregar as opções de comissão');
        break;
    }
  }

  const handleCep = (cep) => {
    setCep(cep);
    searchAdressForCEP(filterCharacters(cep));
  }

  const handleConta = (value) => {
    value = filterCharacters(value)
    let digit = value[value.length - 1];
    let number = 0;
    if (value.length > 1)
      number = parseInt(value.slice(0, value.length - 1))
    if (digit && number.toString().length < 10)
      setConta((number) + '-' + digit)
  }

  const handleCheckConta = (input) => {
    if (input.value > 0) {
      let value = filterCharacters(input.value)
      let digit = value[value.length - 1];
      let number = parseInt(value.slice(0, value.length - 1));
      input.value = number + '-' + digit;
    }
    checkContaBanco(input)
  }

  const handleShowFuncionarios = () => {
    setShowButtons(false);
    setShowSelectFuncionarios(true);
  }

  const handleAdicionar = () => {
    setShowButtons(false);
    setText("Adicionar Funcionário");
    setTextConfirm("Criação");
    setTitleConfirm("criado");
    setShowForm(true);
    setShowCargo(true);
    setShowChaveJ(true);
  }

  const selectFuncionario = () => {
    if (funcionario) {
      setShowBackDrop(true);
      setShowButtons(false);
      setText("Editar Funcionário");
      setTextConfirm("Edição");
      setTitleConfirm("editado");
      setShowCargo(false);
      setShowForm(true);
      let func = funcionario.split(",");
      setId(parseInt(func[0]))
      searchFuncionario(parseInt(func[0]), func[1])
    }
    else {
      error("Nenhum funcionário escolhido", "Necessário escolher um funcionário para editá-lo.")
    }
  }

  return { searchFuncionarios, searchBancos, searchEmpresas, searchSupervisoresAndAdministradores, handleAdicionar, handleShowFuncionarios, selectFuncionario, handleCep, handleConta, handleCheckConta, createInput, createUpdateFunc, showButtons, showSelectFuncionarios, showForm, showChaveJ, showCargo, showCredito, showConsorcio, showContaCorrente, showOurocap, showBbDental, showSeguros, showComissaoSupervisor, showOutros, showAjuste, text, funcionarios, estadosCivis, empresas, supervisores, bancos, comissoes, funcionario, estadoCivil, empresa, supervisor, banco, nome, chavej, email, cpf, rg, expedidor, dataNascimento, dataInicio, profissao, cargo, atuacao, cep, logradouro, bairro, numero, complemento, localidade, uf, tipoConta, variacao, agencia, checkConta, conta, checkPix, tipoPix, pix, salario, ajuda, rendimento, adiantamento, internet, aluguel, telefone, credito, consorcio, contaCorrente, ourocap, bbDental, seguros, comissaoSupervisor, outros, ajuste, setFuncionario, setNome, setChavej, setEmail, setCpf, setRg, setExpedidor, setDataNascimento, setDataInicio, setSexo, setEstadoCivil, setProfissao, setCamisa, setEmpresa, setCargo, setSupervisor, setAtuacao, setLogradouro, setBairro, setNumero, setComplemento, setLocalidade, setUf, setCheckConta, setTipoConta, setVariacao, setAgencia, setBanco, setCheckPix, setTipoPix, setPix, setCusto, setSalario, setAjuda, setRendimento, setAdiantamento, setInternet, setAluguel, setTelefone, setCredito, setConsorcio, setContaCorrente, setOurocap, setBbDental, setSeguros, setComissaoSupervisor, setOutros, setAjuste, sexos, camisas, cargos, tiposConta, tiposPix, custos, comissao, sexo, camisa, showBackDrop, setShowBackDrop, custo }
}
