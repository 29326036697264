import prop from 'prop-types';
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

export const SelectField = ({ id, text, value, setoption, options }) => (
    <Box>
        <FormControl fullWidth sx={{ marginY: 1, minWidth: '250px' }}>
            <InputLabel>{text}</InputLabel>
            <Select id={id} name={id} autoWidth value={value} onChange={(e) => setoption(e.target.value)}>
                {options &&
                    options.map((option, index) =>
                        <MenuItem key={index} value={option.value}>
                            {option.label}
                        </MenuItem>
                    )}
            </Select>
        </FormControl>
    </Box>
);

SelectField.defaultProps = {
    text: '',
    setoption: () => { },
    options: [],
}

SelectField.propTypes = {
    id: prop.string.isRequired,
    text: prop.string,
    value: prop.oneOfType([
        prop.string,
        prop.number,
    ]).isRequired,
    setoption: prop.func,
    options: prop.array,
}
