import { BsFillPersonPlusFill, BsTable, BsGrid, BsFillFileEarmarkPlusFill, BsFillFileEarmarkLockFill, BsAwardFill } from "react-icons/bs";
import { FaGraduationCap } from "react-icons/fa";

export const SideBarData = [
  {
    title: 'Dashboard',
    path: '/',
    icon: <BsGrid />,
    block: []
  },
  {
    title: 'Adicionar',
    path: '/form',
    icon: <BsFillPersonPlusFill />,
    block: ['agente']
  },
  {
    title: 'Funcionários',
    path: '/table',
    icon: <BsTable />,
    block: ['agente']
  },
  {
    title: 'Documento',
    path: '/doc',
    icon: <BsFillFileEarmarkPlusFill />,
    block: ['agente']
  },
  {
    title: 'Comodato',
    path: '/lending',
    icon: <BsFillFileEarmarkLockFill />,
    block: ['agente']
  },
  {
    title: 'Certificado',
    path: '/certificate',
    icon: <BsAwardFill />,
    block: ['agente']
  },
  {
    title: 'Cursos',
    path: '/learning',
    icon: <FaGraduationCap />,
    block: []
  }
];