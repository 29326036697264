import { useState, useCallback } from 'react';
import { saveAs } from "file-saver";

import axios from 'axios';
import { error, success } from '../../util/alert';
import { useCookies } from 'react-cookie';
import { checkAuthenticity } from '../../util/auth';
import { BACKEND_URL } from '../../util/config';
import { filterCharacters } from '../../util/filter';
import { formatterCEP, formatterCNPJ, formatterCPF, formatterDate } from '../../util/formatter';
import { MdRemove } from 'react-icons/md';

export const LendingHelper = () => {
  const [cookie, setCookie] = useCookies(['auth']);

  const [funcionario, setFuncionario] = useState('');
  const [empresa, setEmpresa] = useState('');

  const [funcionarios, setFuncionarios] = useState([]);
  const [empresas, setEmpresas] = useState([]);

  const [infoFuncionario, setInfoFuncionario] = useState('');
  const [infoEmpresa, setInfoEmpresa] = useState('');

  const [count, setCount] = useState(0);
  // const [bem, setBem] = useState('');
  // const [modelo, setModelo] = useState('');
  // const [serial, setSerial] = useState('');
  // const [status, setStatus] = useState('');
  // const [observacao, setObservacao] = useState('');

  const [items, setItems] = useState([]);

  /*Função para procurar funcionários dinamicamente. */
  const searchFuncionarios = useCallback(async () => {
    await axios
      .get(BACKEND_URL + "funcionarios",
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
      .then(response => {
        let funcionariosData = response.data.funcionarios;
        let funcionarios = [];

        funcionariosData.forEach(function (funcionario) {
          funcionarios.push({
            value: funcionario.cargo_id + ',' + funcionario.cargo,
            label: funcionario.nome,
          })
        })
        setFuncionarios(funcionarios);
      })
      .catch(erro => {
        console.log(erro);
        checkAuthenticity(erro.response.status);
      })
  }, [cookie])

  /*Função para procurar empresas dinamicamente. */
  const searchEmpresas = useCallback(async () => {
    await axios
      .get(BACKEND_URL + "empresas/",
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
      .then(response => {
        let empresasData = response.data.empresas;
        let empresas = [];

        empresasData.forEach(empresa => {
          empresas.push({
            value: empresa.cnpj,
            label: empresa.nome,
          })
        })
        setEmpresas(empresas);
      })
      .catch(erro => {
        console.log(erro);
        checkAuthenticity(erro.response.status);
      })
  }, [cookie])

  /*Função para procurar um funcionário dinamicamente. */
  const searchFuncionario = useCallback(async (id, currentCargo) => {
    await axios
      .get(BACKEND_URL + currentCargo + "/" + id,
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
      .then(response => {
        let jsonData = {
          funcionario: {

          }
        };

        jsonData.funcionario.nome = response.data.nome;
        jsonData.funcionario.cpf = formatterCPF(response.data.cpf);
        jsonData.funcionario.rg = response.data.rg;
        jsonData.funcionario.data_nasc = formatterDate(response.data.data_nasc);
        jsonData.funcionario.endereco = `${response.data.endereco.rua}, Bairro: ${response.data.endereco.bairro}, nº ${response.data.endereco.numero}, CEP: ${formatterCEP(response.data.endereco.cep)}, ${response.data.endereco.cidade} - ${response.data.endereco.uf}`;
        jsonData.funcionario.dados_bancarios = response.data.dados_bancarios;
        jsonData.funcionario.pix = response.data.pix;
        jsonData.funcionario.email = response.data.email;
        jsonData.funcionario.telefone = response.data.telefone;

        setInfoFuncionario(jsonData);
      })
      .catch(erro => {
        error('Erro', 'Não foi possível carregar as informações.')
        console.log(erro);
        checkAuthenticity(erro.response.status);
      })
  }, [cookie])

  /*Função para procurar um funcionário dinamicamente. */
  const searchEmpresa = useCallback(async (cnpj) => {
    await axios
      .get(BACKEND_URL + "empresa/" + cnpj,
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
      .then(response => {
        let jsonData = {
          empresa: {

          }
        };

        jsonData.empresa.contratante = response.data.nome;
        jsonData.empresa.cnpj = formatterCNPJ(response.data.cnpj);
        jsonData.empresa.endereco = response.data.sede;
        jsonData.empresa.cep = response.data.cep_sede;
        jsonData.empresa.representante = response.data.representante;
        jsonData.empresa.cpf_representante = response.data.cpf_representante;

        setInfoEmpresa(jsonData);
      })
      .catch(erro => {
        error('Erro', 'Não foi possível carregar as informações.')
        console.log(erro);
        checkAuthenticity(erro.response.status);
      })
  }, [cookie])

  /*Função para gerar termo de comodato do funcionário. */
  function generateComodato(event) {
    event.preventDefault();

    let itemsFormat = items;
    for (let i = 0; i < itemsFormat.length; i++) {
      delete itemsFormat[i].id;
      delete itemsFormat[i].operations;
    }

    let jsonData = Object.assign({}, infoFuncionario, infoEmpresa, { patrimonios: itemsFormat });
    console.log(jsonData);

    axios
      .post(BACKEND_URL + "funcionario/contrato/2", jsonData,
        {
          responseType: "blob",
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
      .then(response => {
        console.log(response);
        saveAs(response.data, "Comodato(" + infoFuncionario.funcionario.nome + ").docx");
        success('Sucesso', "Termo de comodato gerado com sucesso!");
      })
      .catch(erro => {
        error('Erro', 'Não foi possível carregar as informações.')
        console.log(erro);
        checkAuthenticity(erro.response.status);
      })
  }

  const handleFuncionario = (funcionario) => {
    setFuncionario(funcionario);
    let func = funcionario.split(",");
    searchFuncionario(parseInt(func[0]), func[1]);
  }

  const handleEmpresa = (empresa) => {
    setEmpresa(empresa);
    searchEmpresa(filterCharacters(empresa));
  }

  const removeItemOfTable = (e, index) => {
    e.preventDefault();

    setItems(oldValues => {
      return oldValues.filter((item, i) => item.id !== index)
    })
  }

  const addItemToTable = (e) => {
    e.preventDefault();

    const data = new FormData(e.currentTarget);

    let bem = data.get('bem');
    let modelo = data.get('modelo');
    let serial = data.get('serial');
    let status = data.get('status');
    let observacao = data.get('observacao');

    let item = {
      id: count,
      bem,
      modelo,
      serial,
      status,
      observacao,
      operations: <button className="text-white btn-icon btn-delete" title="Remover item" onClick={(e) => removeItemOfTable(e, count)}><MdRemove /></button>
    }

    setItems(items => [...items, item]);
    setCount(newCount => count + 1);

    data.set('bem', '');
    data.set('modelo', '');
    data.set('serial', '');
    data.set('status', '');
    data.set('observacao', '');
  }

  return { searchFuncionarios, searchEmpresas, handleEmpresa, handleFuncionario, addItemToTable, generateComodato, items, empresas, funcionarios, empresa, funcionario }
}
