import { useState } from 'react';
import { error } from '../../util/alert';
import axios from 'axios';
import { BACKEND_URL } from '../../util/config';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

export const RedefineHelper = () => {
  const { token } = useParams();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const createNewPassword = (e) => {
    e.preventDefault();

    if (password.length === 0 || confirmPassword.length === 0)
      error("Campo(s) não preenchidos", "Preencha todos os campos para continuar")
    else if (password !== confirmPassword)
      error("Senha inválida", "As duas senhas precisam ser iguais")
    else {
      // desabilita o botão
      document.getElementById('btnSubmit').disabled = true;

      //monta o json para envio dos dados
      var jsonData = {
        token: token,
        senha: password,
      };

      //redefine a senha do usuário
      axios
        .post(BACKEND_URL + "user/mudar_senha/", jsonData,
          {
            headers: {
              Authorization: ["Bearer", token].join(" "),
            },
          })
        .then(response => {
          //reabilita o botão
          document.getElementById('btnSubmit').disabled = false;
          Swal.fire({
            title: 'Sucesso',
            text: 'Parabéns, sua senha foi alterada com sucesso!',
            icon: 'success',
            confirmButtonText: 'Fechar',
          }).then((result) => {
            if (result.isConfirmed)
              window.location.href = '/login';
          })
        })
        .catch(erro => {
          console.log(erro);
          if (erro.response.status === 401)
            error('Credenciais inválidas', 'Credenciais incorreta(s) ou não cadastradas.');
          else
            error('Erro desconhecido', 'Aconteceu algo inesperado, tente novamente. Caso o erro persista, entre em contato com o suporte.');
        });

      //reabilita o botão
      document.getElementById('btnSubmit').disabled = false;
    }
  }

  return { createNewPassword, password, confirmPassword, setPassword, setConfirmPassword }
}
