/* Formata números de CPF
*/
export const formatterCPF = (cpf) => {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
}

/* Formata números de CNPJ
*/
export const formatterCNPJ = (cnpj) => {
    return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
}

/* Formata números de Agência
*/
export const formatterAgencia = (agencia) => {
    return agencia.replace(/(\d{4,5})(\d{1})/, "$1-$2")
}

/* Formata números de Conta
*/
export const formatterConta = (conta) => {
    return conta.replace(/(\d{5,9})(\d{1})/, "$1-$2")
}

/* Formata números de CEP
*/
export const formatterCEP = (cep) => {
    return cep.replace(/(\d{5})(\d{3})/, "$1-$2")
}

/* Formata moeda para o padrão brasileiro
*/
export const formatterMoeda = (moeda) => {
    if (typeof moeda === "number") {
        if(moeda > 999.99)
            return moeda.toFixed(2).replace(/(\d{1,3})(\d{3})\.(\d{2}$)/, "$1.$2,$3")
        else if(moeda <= 999.99)
            return moeda.toFixed(2).replace(/(\d{1,3})\.(\d{2}$)/, "$1,$2")
    }
}

export const formatterDate = (date) => {
    return date.split('-').reverse().join('/');
}
