import { useEffect } from 'react';
import prop from 'prop-types';
import '../../styles/modal.css';
import { Info } from '../../components/Info';
import { formatterAgencia, formatterConta, formatterCPF, formatterCNPJ, formatterMoeda } from '../../util/formatter';
import { dateToString } from '../../util/filter';
import { capitalize } from '../../util/typography';
import { InfoListCert } from '../../components/InfoListCert';
import { MyInfoHelper } from './helper';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack } from '@mui/material';

export const MyInfo = ({ showInfo, setShowInfo, id, cargo, showDeleteCert }) => {
  const { cookie, showData, getSexo, funcionario, deleteCert } = MyInfoHelper();

  useEffect(() => {
    let id_func = id ? id : cookie.id;
    let cargo_func = cargo ? cargo : cookie.tipo;

    showData(id_func, cargo_func);
  }, []);

  return (
    <div>
      {funcionario &&
        <Dialog
          id="info-func"
          open={showInfo}
          onClose={() => setShowInfo(false)}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>{funcionario.nome}</DialogTitle>
          </Box>
          <DialogContent
            sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%', textAlign: 'start' }}
          >
            <Stack
              divider={<Divider flexItem />}
              spacing={2}
            >
              {funcionario.chavesJ &&
                funcionario.chavesJ.map((chavej, index) =>
                  <Info key={index} title='ChaveJ:' text={`${chavej.chavej} (${chavej.ativo ? 'Ativo' : 'Inativo'}) - ${chavej.empresa.nome} (${formatterCNPJ(chavej.empresa.cnpj)})`} />
                )
              }
              <Stack
                direction="row"
                divider={<Divider orientation='vertical' flexItem />}
                spacing={2}
                sx={{ justifyContent: "space-between", }}
              >
                <Info title='CPF:' text={formatterCPF(funcionario.cpf)} col={2} />
                <Info title='RG:' text={`${funcionario.rg}(${funcionario.orgao_expedidor})`} col={2} />
              </Stack>
              <Stack
                direction="row"
                divider={<Divider orientation='vertical' flexItem />}
                spacing={2}
                sx={{ justifyContent: "space-between", }}
              >
                <Info title='Sexo:' text={getSexo(funcionario.sexo)} col={2} />
                <Info title='Data de Nascimento:' text={dateToString(funcionario.data_nasc)} col={2} />
              </Stack>
              <Info title='Email:' text={funcionario.email} />
              <Stack
                direction="row"
                divider={<Divider orientation='vertical' flexItem />}
                spacing={2}
                sx={{ justifyContent: "space-between", }}
              >
                <Info title='Telefone:' text={funcionario.telefone} col={2} />
                <Info title='Data de Início:' text={funcionario.data_inicio ? dateToString(funcionario.data_inicio) : 'Não cadastrada'} col={2} />
              </Stack>
              <Stack
                direction="row"
                divider={<Divider orientation='vertical' flexItem />}
                spacing={2}
                sx={{ justifyContent: "space-between", }}
              >
                <Info title='Estado Civil:' text={capitalize(funcionario.estado_civil)} col={2} />
                <Info title='Camisa:' text={funcionario.tam_camisa} col={2} />
              </Stack>
              <Info title='Profissão:' text={capitalize(funcionario.profissao)} />
              {funcionario.supervisor &&
                <Info title='Supervisor:' text={funcionario.supervisor} />
              }
              {funcionario.ag_atuacao &&
                <Info title='Agência de atuação:' text={funcionario.ag_atuacao} />
              }

              <Stack
                direction="row"
                divider={<Divider orientation='vertical' flexItem />}
                spacing={2}
                sx={{ justifyContent: "space-between", }}
              >
                <Info title='Rua:' text={funcionario.endereco.rua} col={2} />
                <Info title='Número:' text={funcionario.endereco.numero} col={2} />
              </Stack>
              <Stack
                direction="row"
                divider={<Divider orientation='vertical' flexItem />}
                spacing={2}
                sx={{ justifyContent: "space-between", }}
              >
                <Info title='Bairro:' text={funcionario.endereco.bairro} col={funcionario.endereco.complemento ? 2 : 1} />
                {funcionario.endereco.complemento &&
                  <Info title='Complemento:' text={funcionario.endereco.complemento} col={2} />
                }
              </Stack>
              <Info title='Cidade(UF):' text={`${funcionario.endereco.cidade} (${funcionario.endereco.uf})`} />

              {funcionario.dados_bancarios &&
                <>
                  {
                    funcionario.dados_bancarios.tipo_conta === 'corrente' ?
                      <Info title='Tipo de Conta:' text={'Corrente'} /> :
                      <Stack
                        direction="row"
                        divider={<Divider orientation='vertical' flexItem />}
                        spacing={2}
                        sx={{ justifyContent: "space-between", }}
                      >
                        <Info title='Tipo de Conta:' text={'Poupança'} col={2} />
                        <Info title='Variação:' text={funcionario.dados_bancarios.variacao_poupanca} col={2} />
                      </Stack>
                  }
                  <Stack
                    direction="row"
                    divider={<Divider orientation='vertical' flexItem />}
                    spacing={2}
                    sx={{ justifyContent: "space-between", }}
                  >
                    <Info title='Agência:' text={formatterAgencia(funcionario.dados_bancarios.agencia)} col={2} />
                    <Info title='Conta:' text={formatterConta(funcionario.dados_bancarios.conta)} col={2} />
                  </Stack>
                  <Info title='Banco(Cod.):' text={`${funcionario.dados_bancarios.banco.nome} (${funcionario.dados_bancarios.banco.codigo})`} />
                </>
              }
              {funcionario.pix &&
                <Stack
                  direction="row"
                  divider={<Divider orientation='vertical' flexItem />}
                  spacing={2}
                  sx={{ justifyContent: "space-between", }}
                >
                  <Info title='Tipo de Conta:' text={'Pix'} col={2} />
                  <Info title='Pix:' text={funcionario.pix.chave} col={2} />
                </Stack>
              }
              {funcionario.comissao.credito > 0.00 &&
                <Info title='Comissão Crédito:' text={`R$ ${formatterMoeda(funcionario.comissao.credito)} `} />
              }
              {funcionario.comissao.consorcio > 0.00 &&
                <Info title='Comissão Consórcio:' text={`R$ ${formatterMoeda(funcionario.comissao.consorcio)} `} />
              }
              {funcionario.comissao.conta_corrente > 0.00 &&
                <Info title='Comissão Conta Corrente:' text={`R$ ${formatterMoeda(funcionario.comissao.conta_corrente)} `} />
              }
              {funcionario.comissao.ourocap > 0.00 &&
                <Info title='Comissão Ourocap:' text={`R$ ${formatterMoeda(funcionario.comissao.ourocap)} `} />
              }
              {funcionario.comissao.bb_dental > 0.00 &&
                <Info title='Comissão BB Dental:' text={`R$ ${formatterMoeda(funcionario.comissao.bb_dental)} `} />
              }
              {funcionario.comissao.seguros > 0.00 &&
                <Info title='Comissão Seguros:' text={`R$ ${formatterMoeda(funcionario.comissao.seguros)} `} />
              }
              {funcionario.comissao.com_supervisor > 0.00 &&
                <Info title='Comissão Supervisor:' text={`R$ ${formatterMoeda(funcionario.comissao.com_supervisor)} `} />
              }
              {funcionario.comissao.outros > 0.00 &&
                <Info title='Comissão Outros:' text={`R$ ${formatterMoeda(funcionario.comissao.outros)} `} />
              }
              {funcionario.comissao.ajuste > 0.00 &&
                <Info title='Ajuste:' text={`R$ ${formatterMoeda(funcionario.comissao.ajuste)} `} />
              }
              {funcionario.certificados.length > 0 &&
                <InfoListCert title="Certificações" list={funcionario.certificados} removeItem={showDeleteCert ? deleteCert : null} />
              }
            </Stack>
          </DialogContent>
          <DialogActions sx={{ pb: 3, px: 3 }}>
            <Button onClick={() => setShowInfo(false)}>Fechar</Button>
          </DialogActions>
        </Dialog>
      }
    </div>
  )
}

MyInfo.defaultProps = {
  id: null,
  cargo: null,
  showDeleteCert: false
}

MyInfo.propTypes = {
  showInfo: prop.bool.isRequired,
  setShowInfo: prop.func.isRequired,
  id: prop.number,
  cargo: prop.string,
  showDeleteCert: prop.bool
}
