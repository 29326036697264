//Máscaras para os campos de dados
export const PhoneMask = "(99) 99999-9999";
export const ChaveJMask = "J*999999";
export const EmailMask = /^\S*@?\S*$/;
export const CPFMask = "999.999.999-99";
export const CEPMask = "99999-999";
export const AgenciaMask = "9999-9";
export const RGMask = "9999999999999999999";
export const DateMask = "dD/mM/YYYY";
export const formatChars = {
    'Y': '[0-9]',
    'd': '[0-3]',
    'D': '[0-9]',
    'm': '[0-1]',
    'M': '[0-9]'
  };