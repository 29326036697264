import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from "react-cookie";
import Helmet from 'react-helmet';

import { Home } from './templates/Home';

import * as Sentry from "@sentry/react";
import { SENTRY_DSN_FRONTEND } from './util/config';

Sentry.init({
    dsn: SENTRY_DSN_FRONTEND,
    integrations: [
        // Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    // tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
    <CookiesProvider>
        <Helmet>
            <html lang="pt-br" />
            <title>GilGates Admin</title>
            <meta name="description" content="Sistema de gestão de funcionários da Empresa Conexão Brasil" />
            <meta name="theme-color" content="#FF0000" />
            <meta http-equiv="Content-Language" content="pt-br" />
            <meta http-equiv="Content-Type" content="text/html;charset=utf-8" />
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
            </style>
        </Helmet>
        <Home />
    </CookiesProvider>,
    document.getElementById('root')
);